import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';

class CreateExect extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    selectedImage:undefined,
    cadastro_executante: {
 
        razao_social: '',
        cnpj: '',
        endereco: '',
        estado: '',
        cep: '',
        telefone: '',
        email: '',
        site:''
    }
    };
  }

  componentDidMount() {
    document.title = "Adicionar executante";
 
}

handleInputChange = (event) => {
  const { name, value } = event.target;
  this.setState(prevState => ({
    cadastro_executante: {
      ...prevState.cadastro_executante,
      [name]: value
    }
  }));
};

handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) {
    this.setState({
      selectedImage: file
    });
  } else {

    alert('Por favor, selecione um arquivo de imagem válido.');
  }
};


  render() {
  var {cadastro_executante,selectedImage }= this.state;
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Adicionar executante</button>
        </div></header>
        <main>
          
          
        <div className="py-12">
<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <h3>DADOS</h3>
            </div>
    <div class="card-body">
    <table class="table table-bordered">
      <thead>
     
        <tr>
          <th scope="col">TITULO</th>
          <th scope="col">REFERENCIA</th>
        </tr>
      </thead>
      <tbody>
      <tr>
            <td>LOGO:</td>
            <td>
            <input class="form-control" type="file"    onChange={this.handleImageChange}  id="formFile"/>
            </td>
          </tr>
          <tr>
            <td>RAZÃO SOCIAL:</td>
            <td><input type="text" className="form-control" placeholder="Digite a razão social" name="razao_social" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>CNPJ:</td>
            <td><input type="text" className="form-control" placeholder="Digite o cnpj" name="cnpj" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ENDEREÇO:</td>
            <td><input type="text" className="form-control" placeholder="Digite o endereço" name="endereco" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ESTADO:</td>
            <td>
            <select
                className="form-control"
                name="estado"
                 onChange={this.handleInputChange}
           
              >
                <option value="">Selecione o estado</option>
                {[
        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
      ].map((estado) => (
                  <option key={estado} value={estado}>
                    {estado}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          
         
          <tr>
            <td>CEP:</td>
            <td><input type="text" className="form-control" placeholder="Digite o cep" name="cep" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>TELEFONE:</td>
            <td><input type="text" className="form-control" placeholder="Digite o telefone" name="telefone" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>E-MAIL:</td>
            <td><input type="text" className="form-control" placeholder="Digite o email" name="email" onChange={this.handleInputChange} /></td>
          </tr>
        </tbody>
    </table>
    </div>
    <div class="card-footer">
    <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
    
     if(selectedImage == undefined){
      toast.error('Selecione o LOGO!')
      return false;
     }
     console.log(cadastro_executante)
     const id = toast.loading("Enviando...")
     const formData = new FormData();
     Object.keys(cadastro_executante).forEach(key => {
      formData.append(key, cadastro_executante[key]);
    });
     formData.append('image', selectedImage);
     fetch(`${apiUrl}/executante/${window.location.pathname.split("/")[2]}`, {
      method: 'POST',
      body: formData
     }).then(response => {
     if(response.status == 200){
         response.json().then(data=>{
             toast.update(id, { render:"Cadastro realizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});

         this.props.navigate(`/cadastro-executante/preview/${data.token}`)
         })
     }
     if(response.status == 422){
         response.json().then(data=>{
             toast.update(id, { render:data.error, type: "error", isLoading: false,autoClose: 3000});

         })
     }
     }).catch(err=>{
      toast.update(id, { render:"error", type: "error", isLoading: false,autoClose: 3000});

     })
    }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
    </div>
         </div>

      </div>
  
</div>
</div>  
          
          
          
          
         
      













         </main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateExect;