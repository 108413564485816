import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';
import SelectSearch from 'react-select-search';
import InputMask from 'react-input-mask';
class CreateLau extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
    selectedImage:undefined,
    selectedExec:'',
    selectedEmpresa: '',
    empresas:[],
    executantes:[],
    laudo: {
      executante: '',
      data:'',
      maquina: '',
      art_vinculada: '',
      site: ''
    }
    };
    this.divRef = React.createRef();
    this.divRef2 = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
  }

Dia(){
  const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Janeiro é 0
    const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}



  componentDidMount() {
    document.title = "Criar Laudo";
    fetch(`${apiUrl}/listar-dados/`)
      .then(response => response.json())
      .then(data => {
        // Atualiza o estado com os dados obtidos da API
        this.setState({ empresas: data.cont,executantes:data.exec, Loader: false });
      
        this.setState((prevState) => ({
          laudo: {
            ...prevState.laudo,
            data: this.Dia()
          }
        }));


      

      })
      .catch(error => {
        // Trata erros de requisição
        this.setState({ error: 'Erro ao carregar empresas', loading: false });
      });


      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('mousedown', this.handleClickOutside2);
}
componentWillUnmount() {
  document.removeEventListener('mousedown', this.handleClickOutside);
  document.removeEventListener('mousedown', this.handleClickOutside2);
}

handleClickOutside(event) {
  if (this.divRef.current && !this.divRef.current.contains(event.target)) {
this.setState({slc2:undefined})
  }
}

handleClickOutside2(event) {
  if (this.divRef2.current && !this.divRef2.current.contains(event.target)) {
this.setState({slc1:undefined})
  }
}


handleInputChange = (event) => {
  const { name, value } = event.target;
  this.setState(prevState => ({
    laudo: {
      ...prevState.laudo,
      [name]: value
    }
  }));
};

handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) {
    this.setState({
      selectedImage: file
    });
  } else {

    alert('Por favor, selecione um arquivo de imagem válido.');
  }
};



  render() {
  var {laudo,selectedImage,empresas,executantes }= this.state;
  
  const options = empresas.map((empresa) => ({
    name: `${empresa.razao_social} - ${empresa.cnpj}`,
    value: empresa.cnpj,
  }));

  const options2 = executantes.map((empresa) => ({
    name: `${empresa.razao_social} - ${empresa.cnpj}`,
    value: empresa.cnpj,
  }));




  if(this.state.Loader == true){
    return(<>
    <div className="loader-container">
    <div class="loader"></div>
    </div>
   
    </>)
}
const contratante = empresas.find(item => item.token === window.location.pathname.split("/")[2]);


    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Criar Laudo</button>
        </div></header>
        <main>
          
          
        <div className="py-12">
<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <h3>DADOS</h3>
            </div>
    <div class="card-body">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">TITULO</th>
          <th scope="col">REFERENCIA</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td>EXECUTANTE:</td>
            <td>
            <div className='slc-master' ref={this.divRef2} onClick={()=>{
                this.setState({slc1:true})
              }}>
                 <span>{this.state.selectedExec== "" ? "Selecione o executante:" : this.state.selectedExec}</span>
                 {this.state.slc1 != undefined && <>
  <SelectSearch  options={options2}  name="executante"  emptyMessage="Sem resultados!" onChange={(value)=>{
            this.setState({selectedExec:value,slc1:undefined})
            
            this.setState(prevState => ({
              laudo: {
                ...prevState.laudo,
                ['executante']: value
              }
            }));


            }} placeholder="Pesquise" search/>
</>}
            </div>
            </td>
          </tr>
          <tr>
            <td>CONTRATANTE:</td>
            <td><input type="text" className="form-control" placeholder="Contratante:" value={`${contratante.razao_social} - ${contratante.cnpj}`}  disabled="true"/></td>
     
          </tr>

          <tr>
            <td>Rev:</td>
            <td><input type="text" className="form-control" placeholder="Digite:" name="rev" onChange={this.handleInputChange} /></td>
          </tr>

          <tr>
            <td>Número:</td>
            <td><input type="text" className="form-control" placeholder="Digite:" name="nmr" onChange={this.handleInputChange} /></td>
          </tr>

          <tr>
            <td>MÁQUINA / EQUIPAMENTO:</td>
            <td><input type="text" className="form-control" placeholder="Digite a máquina/equipamento" name="maquina" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ART VINCULADA: Nº</td>
            <td><input type="text" className="form-control" placeholder="Digite a art vinculada" name="art_vinculada" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ESTADO:</td>
            <td><input type="text" className="form-control" placeholder="Digite o estado" name="estado" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>DATA:</td>
            <td>
      <InputMask
      className="form-control"
        mask="99/99/9999"
        name="data" value={this.state.laudo.data} onChange={this.handleInputChange} 
        
     />
              
              
</td>
          </tr>
          
          <tr>
            <td>Site:</td>
            <td><input type="text" className="form-control" placeholder="Digite o site" name="site" onChange={this.handleInputChange} /></td>
          </tr>
         
        </tbody>
    </table>
    </div>
    <div class="card-footer">
    <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
    

     const id = toast.loading("Enviando...")
     const formData = new FormData();
     formData.append('contratante',contratante.cnpj);
    Object.keys(laudo).forEach(key => {
      formData.append(key, laudo[key]);
    });
     formData.append('image', selectedImage);
     fetch(`${apiUrl}/laudo-tecnico/${window.location.pathname.split("/")[2]}/adicionar`, {
      method: 'POST',
      body: formData
     }).then(response => {
     if(response.status == 200){
         response.json().then(data=>{
             toast.update(id, { render:"Cadastro realizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});

        this.props.navigate(`/laudo-tecnico/preview/${data.token}`)
         })
     }
     if(response.status == 422){
         response.json().then(data=>{
             toast.update(id, { render:data.error, type: "error", isLoading: false,autoClose: 3000});

         })
     }
     }).catch(err=>{
      toast.update(id, { render:"error", type: "error", isLoading: false,autoClose: 3000});

     })
    }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
    </div>
         </div>

      </div>
  
</div>
</div>  
          
          
          
          
         
      













         </main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CreateLau;