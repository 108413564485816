import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
import Modal from '../../Comps/Modal';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import p404 from "../../images/undraw_text_files_au1q.png"
import { faTrash,faEye,faUniversity,faPen,faUpload, faPlus, faArrowLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { Confirm } from 'react-st-modal';
class Textos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader:true,
      data:[],
      Ptxt:'',
      Tpsq:'Nome',
      search:[],
      busca:false,
      inputFocused: false,
    };
  }

  componentDidMount() {
    document.title = "Banco de Textos";
    fetch(`${apiUrl}/textos/list`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

 
_Busca = () =>{
   fetch(`${apiUrl}/busca-usr-laudos/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      },
      body:JSON.stringify({
         Tpsq:this.state.Tpsq,
         Ptxt:this.state.Ptxt
      })
    }).then(response => {
     if(response.status == 200){
      response.json().then(search=>{
         this.setState({busca:true,search})
      })
   
     }
    })
   
}


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <button onClick={()=>{
        this.props.navigate(-1)
      }} class="font-semibold text-xl text-gray-800 leading-tight"><FontAwesomeIcon color='#282828' icon={faArrowLeft} /> Banco de Textos</button></div></header>
      <main>
         
      <div class="py-1">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      
      <div class="row">
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">
 
<h2>Textos</h2>


<button className='btn btn-primary' onClick={()=>{
    this.props.navigate('/textos/adicionar')
}}><FontAwesomeIcon color='#fff' icon={faPlus} /> Adicionar texto</button>

                       


</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                
                        <div>
                   
                        </div>
                        {this.state.busca == true ? <>
                     
                           {this.state.search.length > 0 ? <>
                         


                              {this.state.search.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">Código</th>
                                 <th scope="col">Razão social</th>
                                 <th scope="col">cnpj</th>
                                 <th scope="col">documento</th>
                                 <th scope="col">Opção</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.search.map((item,index)=><>
                           <tr>
                                 <td>{item.token}</td>
                                  <td>
                                  <Link  to={`/${window.location.pathname.split("/")[1]}/${item.token}/list`}>
                                  {item.token}
                                  </Link>
                                  </td>
                                  <td>{item.razao_social}</td>
                                 <td>{item.cnpj}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link  to={`/criar-documento/${item.token}`}>Criar documento</Link>
                                   
                                    </div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}


                         
                            </> : <>
                            <div class="se-re">
      
      <img src={p404}/>
     <p>Desculpe, Esse documento não foi encontrado.</p>
    
    
   
    
    </div>
                            </> }
                      
                        </> : <>
                        {this.state.data.length > 0 ? <>
                           <table class="table ntbl table-bordered">
                           <thead>
                              <tr>
                      
                                 <th scope="col">Texto</th>
                        
                               
                                 <th scope="col">Opção</th>
                           

                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.map((item,index)=><>
                           <tr>
                          
                               
                                  <td>{item.text}</td>
                               
                                 <td>
                                 <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-primary" to={`/textos/edit/${item.id}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger"  onClick={async()=>{
             const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
              'Tem certeza?');
              if(result){
             
               fetch(`${apiUrl}/textos/remover/${item.id}`, {
                  method: 'DELETE',
     
                 }).then(response => {
if(response.status == 200){
  toast.success(('Sucesso!'))
  this.setState({
    data:this.state.data.filter(e => e.id !== item.id)
  })
 
}
                 })
              }
          }}><FontAwesomeIcon color='#fff'  icon={faTrash} /></button>
                       
                                   </div>
                                 </td>
                              
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        <div class="se-re">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
     
      
     
      
      </div>
                        </>}
                       
                        </>} 
                      
                     </div>
                  </div>
               </div>
               <div class="card-footer">
              
                       
               </div>
            </div>
         </div>
      </div>
   </div>
      </div>
      </div>
      
     </div>
     
     </div>
      </main>
     

     <div id="modal-root"></div>
      </>
  
    );
  }
}

export default Textos;