import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faDownload, faPen, faArrowLeft, faPrint, faAngleLeft,} from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import p404 from "../../../images/undraw_text_files_au1q.png"
class InfoCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Loader:true,
        error:false,
        p404:false,
        cadastro_empresa:[],
  
      };
  }

  componentDidMount() {
    document.title = "Informações da empresa";
    fetch(`${apiUrl}/empresas/${window.location.pathname.split("/")[3]}/list`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({
      cadastro_empresa:data,
      Loader:false
    })
})
}
if(response.status == 404){
    this.setState({p404:true,Loader:false})
}
      })
}

 


  render() {
    const { cadastro_empresa,endereco_brasil,endereco_exterior } = this.state;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }


    if(this.state.p404 == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  {document.title}</button>
        </div>
        </header>
       
        <main>
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      
      <div class="row">
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">
<h4>DADOS DO EMPRESA</h4>

<div style={{float:'left'}}>
<Link to={`/cadastro-solicitante/edit/${window.location.pathname.split("/")[3]}`}  class="btn btn-primary float-right ml-2"> <FontAwesomeIcon color='#fff' icon={faPen} />  Editar</Link>


</div>

</div>

               <div class="card-body">
               <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Título</th>
          <th scope="col">Input</th>
        </tr>
      </thead>

      <tbody>
  <tr>
    <td>RAZÃO SOCIAL:</td>
    <td><span>{cadastro_empresa.razao_social || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>CNPJ:</td>
    <td><span>{cadastro_empresa.cnpj || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>LOGO:</td>
    <td><span>{cadastro_empresa.logo != undefined ? <>
    <img class="emp-logo" src={`${apiUrl}/img/${cadastro_empresa.logo}`}/>
    </> : "Não informado!"}</span></td>
  </tr>
  <tr>
    <td>ENDEREÇO:</td>
    <td><span>{cadastro_empresa.endereco || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>ESTADO:</td>
    <td><span>{cadastro_empresa.estado || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>CEP:</td>
    <td><span>{cadastro_empresa.cep || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>Telefone:</td>
    <td><span>{cadastro_empresa.telefone || 'não informado'}</span></td>
  </tr>
  <tr>
    <td>E-mail:</td>
    <td><span>{cadastro_empresa.email || 'não informado'}</span></td>
  </tr>
</tbody>


    </table>
   
               </div>
             
            </div>
         </div>
      </div>
   </div>
      </div>
      </div>
      
     </div>
     
     </div>









        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default InfoCliente;