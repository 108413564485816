import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiUrl } from '../../config';
import { json } from 'react-router-dom';
class Jtf extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data:[],
        text:'undefined'
        }
    }
    componentDidMount(){
 
    const matchedItem = this.props.data.find(item => item.code === this.props.code);
    this.setState({data:this.props.data,text:matchedItem != undefined ? matchedItem.text : ''})
   
    }





handleChange(event){


    if (event.target.value.trim().length == 0) {
        event.target.value = '';
      } else {
  
        fetch(`${apiUrl}/Jtf`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body:JSON.stringify({
                ref:window.location.pathname.split("/")[3],
                code:this.props.code,
                text:event.target.value
            })
          }).then(response => {
response.json().then(data=>{
    if(data.s == "a"){
    toast.success("Salvo!")
    }else{
    toast.success("Editado!")
    }
})
          })
        

      }
}



  render() {
  
    const { type,vz } = this.props;
 
    if(window.location.pathname.split("/")[1] == "previewLau"){
      return(<div>
     <strong>Justificativa: </strong> {this.state.text != '' ? this.state.text : 'Não Aplicável(eis) ' }
      </div>)
    }

if(vz == "v"){
  return(<div code={this.props.code}>
  {this.state.text}
  </div>)
}

    return (<>
    <div class=""  style={{marginTop:15}}>
<label>Justificativa:</label>
<textarea class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
    this.handleChange(event)
}} placeholder="Digite:"></textarea>
</div>
    </>)




    
    
  
  }
}

export default Jtf;