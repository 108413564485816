import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';

import p404 from "../../../images/undraw_text_files_au1q.png"
import { apiUrl } from '../../../config';
class CustoDeDestino extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     custo_de_destino:[]
    };
  }

  componentDidMount() {
    document.title = "Custo de destino";

    fetch(`${apiUrl}/cotacao/preview/${window.location.pathname.split("/")[3]}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      }
    }).then(response => {
if(response.status == 200){
response.json().then(data=>{
this.setState({
custo_de_destino:data.custo_de_destino,
Loader:false})
})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
    })
}


handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log('aaa')
    const numericValue = value.replace(/[^0-9,.]/g, '');
    this.setState(prevState => ({
      custo_de_destino: {
        ...prevState.custo_de_destino,
        [name]: numericValue
      }
    }));
  }


  render() {
  var custo_de_destino = this.state.custo_de_destino;
  if(this.state.Loader == true){
      return(<>
      <div className="loader-container">
      <div class="loader"></div>
      </div>
     
      </>)
  }
  if(this.state.p404 == true){
    return(<>
    <div class="p404">
    
      <img src={p404}/>
     <p>Desculpe, Esse documento não foi encontrado.</p>
     <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
             }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
    
   
    
    </div>
    </>)
  }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Custo de destino</h2></div></header>
        <main>
          

          
          
<div className="py-12">
<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <h3>CUSTO DE DESTINO</h3>
            </div>
            
            
            <table class="table table-bordered">
  <thead>
    <tr>
      <th scope="col">Título</th>
      <th scope="col">R$</th>
    </tr>
  </thead>
  <tbody>
  <tr>
    <td>Desconsolidação¹</td>
    <td><input type="text" name="desconsolidacao" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.desconsolidacao} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>THC Off¹</td>
    <td><input type="text" name="thcOff" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.thcOff} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>ISPS¹</td>
    <td><input type="text" name="isps" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.isps} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Dpp¹</td>
    <td><input type="text" name="dpp" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.dpp} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>THC - Capatazia</td>
    <td><input type="text" name="thcCapatazia" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.thcCapatazia} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>BL Fee</td>
    <td><input type="text" name="blFee" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.blFee} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Desembaraço Aduaneiro</td>
    <td><input type="text" name="desembaracoAduaneiro" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.desembaracoAduaneiro} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Sindicato (DAS)</td>
    <td><input type="text" name="sindicatoDas" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.sindicatoDas} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Habilitação Siscomex (RADAR) e DMM</td>
    <td><input type="text" name="habilitacaoSiscomex" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.habilitacaoSiscomex} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Pacote Terminal com Armazenagem (estimativa para 30 dias)</td>
    <td><input type="text" name="pacoteTerminalArmazenagem" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.pacoteTerminalArmazenagem} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Posicionamento Pesagem</td>
    <td><input type="text" name="posicionamentoPesagem" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.posicionamentoPesagem} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Posicionamento Vistoria</td>
    <td><input type="text" name="posicionamentoVistoria" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.posicionamentoVistoria} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Desova e Ova do Container</td>
    <td><input type="text" name="desovaOvaContainer" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.desovaOvaContainer} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Transferência Container Porto x Zona Secundária</td>
    <td><input type="text" name="transferenciaContainer" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.transferenciaContainer} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Separação</td>
    <td><input type="text" name="separacao" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.separacao} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Taxa administrativa</td>
    <td><input type="text" name="taxaAdministrativa" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.taxaAdministrativa} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Taxa de Expediente</td>
    <td><input type="text" name="taxaExpediente" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.taxaExpediente} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Despesas Portuárias</td>
    <td><input type="text" name="despesasPortuarias" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.despesasPortuarias} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Entrega Container Vazio</td>
    <td><input type="text" name="entregaContainerVazio" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.entregaContainerVazio} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Handling</td>
    <td><input type="text" name="handling" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.handling} onChange={this.handleInputChange} /></td>
  </tr>
  <tr>
    <td>Picking por volume</td>
    <td><input type="text" name="pickingVolume" class="form-control" placeholder="Digite o valor em R$" value={custo_de_destino.pickingVolume} onChange={this.handleInputChange} /></td>
  </tr>
</tbody>
</table>

    <div class="card-footer">
    <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     const id = toast.loading("Enviando...")
     var custo_de_origem = this.state.custo_de_origem;
     fetch(`${apiUrl}/cotacao/custo-de-destino/${window.location.pathname.split("/")[3]}/`, {
         method: 'POST',
         credentials: 'include',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer '+Cookies.get("auth_token"),
         },
         body:JSON.stringify({custo_de_destino}),
     
     }).then(response => {
     if(response.status == 200){
         response.json().then(data=>{
             toast.update(id, { render:"Cadastro realizado com sucesso!", type: "sucess", isLoading: false,autoClose: 3000});

         this.props.navigate(`/cotacao/preview/${data.token}`)
         })
     }
     if(response.status == 422){
         response.json().then(data=>{
             toast.update(id, { render:data.error, type: "error", isLoading: false,autoClose: 3000});

         })
     }
     }).catch(err=>{
     
     })
    }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
  
    </div>
         </div>

      </div>
  
</div>
</div>
      








         </main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CustoDeDestino;