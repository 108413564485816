import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faUpload, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
class ImportCot extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedFile: null
      };
  }
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  }

  componentDidMount() {
    document.title = "Importar cotação";

}

 


  render() {
  
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Importação de cotação</button>
 
        </div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Arquivo da cotação:</h3>
                        

</div>
<div class="card-body">
<div class="mb-3">
  <label for="formFile" class="form-label">Arquivo no formato .xlsx</label>
  <input class="form-control" type="file" onChange={this.handleFileChange} />
</div>
</div>
<div class="card-footer">
  <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{


if(this.state.selectedFile == null){
    toast.error("Selecione um arquivo!")
    return false;
}
const formData = new FormData();
formData.append('file', this.state.selectedFile);

fetch(`${apiUrl}/cotacao/${window.location.pathname.split("/")[3]}/upload`, {
  method: 'POST',
  body: formData
})
.then(response => {
  if (response.ok) {
response.json().then(data=>{
   this.props.navigate(`/${window.location.pathname.split("/")[1]}/preview/${data.token}`)
})
  } else {
    toast.error('Failed to upload file');
  }
})
.catch(error => {
  toast.error('There was an error uploading the file:', error);
});

  }}> <FontAwesomeIcon color='#fff' icon={faUpload} /> Upload</button>
</div>

                        </div>
                    </div>
                </div>
                
            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default ImportCot;