import React from 'react';

// Componente para cada linha da tabela
const TableRow = ({ descricao, valor, percentual, cor }) => (
  <tr style={{ backgroundColor: cor }}>
    <td>{descricao}</td>
    <td>{valor}</td>
    <td>{percentual}</td>
  </tr>
);

// Componente da tabela principal
const TabelaRisco = ({ data }) => {
  // Definindo os dados completos
  const tabelaData = [
    { descricao: "Aceitável", valor: 1, percentual: "14,28", cor: "green" },
    { descricao: "Baixo", valor: 1, percentual: "14,28", cor: "lightgreen" },
    { descricao: "Moderado", valor: 1, percentual: "14,28", cor: "yellow" },
    { descricao: "Significativo", valor: 1, percentual: "14,28", cor: "gold" },
    { descricao: "Alto", valor: 2, percentual: "28,60", cor: "orange" },
    { descricao: "Muito Alto", valor: 0, percentual: "0", cor: "red" },
    { descricao: "Extremo", valor: 1, percentual: "14,28", cor: "darkred" }
  ];

  // Mapeando todos os itens, ajustando os valores se necessário
  const adjustedData = tabelaData.map(item => {
    // Verificar se o item está presente em `data`
    const isIncluded = data.includes(item.descricao.toUpperCase());

    return {
      ...item,
      valor: isIncluded ? item.valor : 0, // Se não estiver presente, valor será 0
      percentual: isIncluded ? item.percentual : "0" // Se não estiver presente, percentual será 0
    };
  });

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Descrição</th>
          <th>Valor Nominal</th>
          <th>Percentil %</th>
        </tr>
      </thead>
      <tbody>
        {adjustedData.map((item, index) => (
          <TableRow 
            key={index} 
            descricao={item.descricao} 
            valor={item.valor} 
            percentual={item.percentual} 
            cor={item.cor} 
          />
        ))}
      </tbody>
    </table>
  );
};

export default TabelaRisco;
