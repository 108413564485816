import React, { Component } from 'react';
import "./r.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose, faImage, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiUrl } from '../../config';
import { json } from 'react-router-dom';
import { Confirm, Modal, StaticDialog } from 'react-st-modal';
import { text } from '@fortawesome/fontawesome-svg-core';
class Rgt extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data:[],
        Modal:false,
        add:undefined,
        text:null,
        matchedItem:undefined,
        image:null,
        file:null
        }
    }
    componentDidMount(){
 
        const matchedItem = this.props.data.find(item => item.code === this.props.code);
        if(matchedItem != undefined){
            this.setState({text:matchedItem.text,img:matchedItem.img,matchedItem})
        }
    
    }

    Preview(img){
        return(<div class="area-8">
        <div class="preview" code={this.props.code} >
            <div class="edit">

    <input type="file" onChange={(event)=>{
    this.handleImage(event)
    }}/>

    <FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img code={this.props.code} src={`${apiUrl}/img/${img}`} />
            </div>
            </div>)
    }


    handleImage(event,ti){

        const file = event.target.files[0];
        if (file) {
          const fileType = file.type;
          if (!fileType.startsWith('image/')) {
            toast.warn("Por favor, selecione uma imagem válida!")
            event.target.value = null;
          } else {
            const formData = new FormData();
            formData.append('imagem', file);
            formData.append('ref',window.location.pathname.split("/")[3]);
            formData.append('code',this.props.code);
           
    
    
    
            fetch(`${apiUrl}/rgt/image`, {
                method: 'POST',
                body: formData
               }).then(response => {
                if(response.status == 200){
    
                  response.json().then(data=>{
    
    if(ti != undefined){
      this.setState(prevState => ({
        matchedItem: {
          ...prevState.matchedItem,
          img:data.img
        }
      }));
    }else{
      this.setState({matchedItem:{img: data.img,id:data.id},add:undefined})
                   
    }
                    
                    toast.success("Salvo!")
 
            
    
                  })
    
                }
            })
    
    
          }
        }
    }


handleChange(event,type){


    if (event.target.value.trim().length == 0) {
        event.target.value = '';
      } else {
  

if(type == "a"){
    fetch(`${apiUrl}/rgt/text`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
            ref:window.location.pathname.split("/")[3],
            code:this.props.code,
            text:event.target.value
        })
      }).then(response => {
response.json().then(data=>{

this.setState({matchedItem:{text,id:data.id},add:undefined})
toast.success("Salvo!")

})
      })
}else{


  fetch(`${apiUrl}/rgt/text`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body:JSON.stringify({
        ref:window.location.pathname.split("/")[3],
        code:this.props.code,
        text:event.target.value
    })
  }).then(response => {
response.json().then(data=>{

  this.setState(prevState => ({
    matchedItem: {
      ...prevState.matchedItem,
      text
    }
  }));

toast.success("Salvo!")

})
  })


  
}

       
        

      }
}

Add(type){
switch(type){
  case 'a':
    return(<>
      <br/>

      <table className="rgt table table-bordered">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    <textarea class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
    this.handleChange(event,"a")
}} placeholder="Digite:"></textarea>
    </td>
    <td>
    <button className='btn btn-danger' onClick={()=>{
          this.setState({add:undefined})
        }}>
          <FontAwesomeIcon icon={faClose}/>
        </button>
    </td>
</tr>
      </tbody>
      </table>




    
    </>)
    break;

  case 'b':
    return(<>
    <br/>
    <table className="rgt table table-bordered">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    <div class="area-8">
<div class="add">
<input type="file" onChange={(e)=>{this.handleImage(e)}}/>
<FontAwesomeIcon icon={faPlus}/>
</div>
</div>
    </td>
    <td>
    <button className='btn btn-danger' onClick={()=>{
          this.setState({add:undefined})
        }}>
          <FontAwesomeIcon icon={faClose}/>
        </button>
    </td>
</tr>
      </tbody>
      </table>




    </>)
    break;
  
  case 'c':
    return(<>
    <br/>
<table className="rgt table table-bordered">
        <thead>
        <tr>
            <th>Texto</th>
            <th>Imagem</th>
            <th>Opções</th>
        </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    <textarea class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}}  placeholder="Digite:"></textarea>
    </td>
    <td>
    <div class="area-8">
 {this.state.image == null ? <>
  <div class="add">
<input type="file" onChange={this.handleImageChange}/>
<FontAwesomeIcon icon={faPlus}/>
</div>
 </> : <>
 <div class="preview" code={this.props.code} >
            <div class="edit">

    <input type="file" onChange={this.handleImageChange}/>

    <FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img code={this.props.code} src={this.state.image} />
            </div>
 
 </>}     


</div>
    </td>
    <td>
      <div>
        <button className='btn btn-primary' onClick={()=>{
          if(this.state.text == null || this.state.text.trim().length == 0){
            toast.warn("Informe o texto")
            return;
          }
          if(this.state.file == null){
            toast.warn("Selecione uma imagem.")
            return;
          }

          const formData = new FormData();
          formData.append('text',this.state.text);
          formData.append('imagem', this.state.file);
          formData.append('ref',window.location.pathname.split("/")[3]);
          formData.append('code',this.props.code);
         
  
  
  
          fetch(`${apiUrl}/rgt/TextImage`, {
              method: 'POST',
              body: formData
             }).then(response => {
              if(response.status == 200){
  
                response.json().then(data=>{
  
  
                 // this.setState({matchedItem:{img: data.img,id:data.id},add:undefined})
                  toast.success("Salvo!")

          
  
                })
  
              }
          })



        }}>
          <FontAwesomeIcon icon={faCheck}/>
        </button>
        <button className='btn btn-danger' onClick={()=>{
          this.setState({add:undefined,image:null,text:'',file:null})
        }}>
          <FontAwesomeIcon icon={faClose}/>
        </button>
      </div>
    </td>
  </tr>
  </tbody>
</table>
    </>)
    break;


}
}

handleImageChange = (event) => {
  const file = event.target.files[0];
    
  // Verifica se o arquivo é uma imagem
  if (file && file.type.startsWith('image/')) {
    const reader = new FileReader();
    
    reader.onloadend = () => {
      this.setState({ image: reader.result,file });
    };
    
    reader.readAsDataURL(file);
  } else {
   toast.warn('Por favor, selecione um arquivo de imagem.');
  }
}


prev(data){



  if(data.text != null && data.img != null){
    return(<>

  <table className="rgt table table-bordered">
        <thead>
        <tr>
            <th>Texto</th>
            <th>Imagem</th>
            <th>Opções</th>
        </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    <textarea class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
  this.handleChange(event,"c")
}} placeholder="Digite:"></textarea>
    </td>
    <td>
    <div class="area-8">

 <div class="preview" code={this.props.code} >
            <div class="edit">

    <input type="file" onChange={(e)=>{
      this.handleImage(e,'ti')
    }}/>

    <FontAwesomeIcon icon={faPen} color='#fff'/>
            </div>
            <img code={this.props.code} src={`${apiUrl}/img/${data.img}`} />
            </div>
 
 


</div>
    </td>
    <td>
      <div>
       
        <button className='btn btn-danger' onClick={async()=>{
              const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                'Tem certeza?');
                if(result){
                    fetch(`${apiUrl}/rgt/delete/${this.state.matchedItem.id}`, {
                        method: 'DELETE',
           
                       }).then(response => {
      if(response.status == 200){
this.setState({matchedItem:undefined,img:null,text:null})
      } })
        
                }
        }}>
          <FontAwesomeIcon icon={faClose}/>
        </button>
      </div>
    </td>
  </tr>
  </tbody>
</table>



    </>)
  }else if(data.text != null){
    return(<>
    <table className="table table-bordered">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    <textarea class="form-control form-control-sm" value={this.state.text} onChange={(e)=>{
    this.setState({text:e.target.value})
}} onBlur={(event)=>{
    this.handleChange(event,"a")
}} placeholder="Digite:"></textarea>
    </td>
    <td>
        <button className='btn btn-danger' onClick={async()=>{
              const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                'Tem certeza?');
                if(result){
                    fetch(`${apiUrl}/rgt/delete/${this.state.matchedItem.id}`, {
                        method: 'DELETE',
           
                       }).then(response => {
      if(response.status == 200){
this.setState({matchedItem:undefined,img:null,text:null})
      } })
        
                }
        }}>
            <FontAwesomeIcon icon={faClose} color='#fff'/>
        </button>
    </td>
  </tr>
  </tbody>
</table>
    </>)
  }else if(data.img != null){
  
    return(<>
    <table className="table table-bordered">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>
    {this.Preview(this.state.matchedItem.img)}
    </td>
    <td>
        <button className='btn btn-danger' onClick={async()=>{
              const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                'Tem certeza?');
                if(result){
                    fetch(`${apiUrl}/rgt/delete/${this.state.matchedItem.id}`, {
                        method: 'DELETE',
           
                       }).then(response => {
      if(response.status == 200){
this.setState({matchedItem:undefined,img:null,text:null})
      } })
        
                }
        }}>
            <FontAwesomeIcon icon={faClose} color='#fff'/>
        </button>
    </td>
  </tr>
  </tbody>
</table>
    </>)

  }
}

  render() {
  
if(this.props.multiple){
  return(<>
  
  <div className="card" style={{marginTop:15}}>
                           

                           <div class="card-header d-flex justify-content-between">
                                                    <h3>Imagens:</h3>
                                                  <div style={{display:'flex'}}>
                         
                         
                         
                         
                                                   
                                                  
                                                   <button className='btn btn-primary' style={{
                                                     marginRight:10
                                                   }} onClick={async(e)=>{
                                                 
                                                 
                                                     this.setState({ModalOpen:true})
                                                     return false;
                                                 
                                                         }}>
                                                   <FontAwesomeIcon icon={faImage} color='#fff'/> Adicionar imagem</button>
                                                
                                                   
                                                
                                                  </div>
                                              
                         
                                                     </div>
                         
                         <div class="card-body">
                          </div>
                          </div>
  
  </>)
}




    if(window.location.pathname.split("/")[1] == "previewLau"){
      return(<div>
      <strong>Registro:</strong> {this.state.matchedItem ? <>
      {this.state.matchedItem.img != null ? <>
      {this.state.matchedItem.img != null && this.state.matchedItem.text != null && <>
     {this.state.matchedItem.text}
      </>}
      <img src={`${apiUrl}/img/${this.state.matchedItem.img}`}/>
      </> : <>{this.state.matchedItem.text}</>}
      </> : 'Não Aplicável(eis)'}
      </div>)
    }
    
if(this.state.add != undefined){
    return(<div className='area-rgt' style={{marginTop:15}}>
    <strong>Registro:</strong>
  {this.Add(this.state.add)}
   

    </div>)
}

    return (<div className='area-rgt' style={{marginTop:15}}>
  <strong>Registro: </strong>

  {this.state.matchedItem ? <>

{this.prev(this.state.matchedItem)}
  </>:<>
    <select onChange={(e)=>{
        this.setState({add:e.target.value})
    }}>
        <option>Selecione:</option>
        <option value="a">Texto</option>
        <option value="b">Imagem</option>
        <option value="c">texto + imagem</option>
    </select>
</>}
    
    
    
    
    

    
    </div>)




    
    
  
  }
}

export default Rgt;