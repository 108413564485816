import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "./p.css"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faDownload,faArrowLeft, faAngleLeft, faList, faSave, faPerson, faUser, faUpload, faClose, faPhotoVideo, faImage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import p404 from "../../../images/undraw_text_files_au1q.png"
import Tree from 'react-d3-tree';
import Treetest from '../../../Comps/Tree';
import { Confirm,CustomDialog,StaticDialog } from 'react-st-modal';
import RiskAppreciationTable from '../../../Comps/RiskAppreciationTable';
import imagem from "../../../../src/images/img.png"
import ImageMarker from '../../../Comps/ImageMarker';
import ImageView from '../../../Comps/ImageView';
import TextView from '../../../Comps/TextView';
class PrevCot extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     data:[],
     normas:[],
     tecs:[],
     Opc:[],
     images:[],
     isOpen: false,
     isActive: false,
     diagram:[],
     form:[],
     grupoi:[],
     ModalOpen:false,
     upl7:[],
     AtMlhs:[],
     upload5:false,
     ImagePreview:'',
     ImgPrev:undefined,
     slcE:false,
     itemE:undefined
     
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }


  AddImg(event){
  

    const file = event.target.files[0];
    
    if (file) {
      const isImage = file.type.startsWith('image/');
      if(isImage == false){
        event.target.value = '';
    toast.warn("Somente imagens são aceitas!")
        return false;
      }
    
    }
    
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ImgPrev: reader.result, 
      });
    };
    reader.readAsDataURL(file); 
    
    
    
    
    this.setState({upl7:event.target.files})
    
    
                            
}

componentDidMount() {
    document.title = "Pré-visualização";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{

  this.setState({
    data,
    diagram:data.diagram,
    form:data.form,
    grupoi:data.groupi,
    Loader:false,
    ImageView:data.ImageView,
    TextView:data.TextView,
    FormHrn:data.FormHrn
  })

  this.setState(prevState => ({
    images: [...prevState.images, ...data.images]
  }));

})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
      })
}

formatCNPJ(cnpj) {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Adiciona os pontos e barra de acordo com o padrão
  cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  return cnpj;
}

handleToggle = () => {
 // this.setState(prevState => ({ isOpen: !prevState.isOpen }));
};
generate_token(length){
  //edit the token allowed characters
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
  var b = [];  
  for (var i=0; i<length; i++) {
      var j = (Math.random() * (a.length-1)).toFixed(0);
      b[i] = a[j];
  }
  return b.join("");
}

handleFileChange(event){

  var file = event.target.files;

  const formData = new FormData();
    if (file) {
      Array.from(file).forEach((file) => {
        formData.append('images', file);
      });


      fetch(`${apiUrl}/solicitante/imagens/`+window.location.pathname.split("/")[3], {
        method: 'POST',
        body: formData
       }).then(response => {
        if(response.status == 200){
          response.json().then(data=>{
            
          


            this.setState((prevState) => {
              
              const novaLista = [data[0], ...prevState.images];
            
              console.log(novaLista)
              return {
              images:novaLista
              };
            });




          })
        }
       })




    }






}
 
   
    

      



handleOpc= (e, index) => {
  const { name, value } = e.target;

var check = this.state.form.some(u=> u.e === `${index}`);
if(check){

  this.setState(prevState => ({
    form: prevState.form.map(item =>
      item.e === `${index}` ? { ...item, marker:`${value}`} : item
    )
  }));

this.setState(prevState => ({
  Opc: {
    ...prevState.Opc,
    [name]:{
      m:value,
  
    }
  }
}));
console.log('a')
}else{
  console.log('b')


  this.setState(prevState => ({
    form: [...prevState.form,{
     e:`${index}`,
     marker:`${value}`
    }]
  }));



this.setState(prevState => ({
  Opc: {
    ...prevState.Opc,
    [name]:{
      m:value
    }
  }
}));


 
}



  
};


  render() {
    var {info} = this.state.data;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.p404 == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }

    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Pré-visualização</button>
          
        </div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    
                   

                   
                   
                   
                   
                    <div className="col-12 mt-3" style={{display:'block'}}>
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>1 - INTRODUÇÃO:</h3>
                            <div>
                              
<a href={`${apiUrl}/generate/docx/${info.token}`}>                          
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .docx</button>
</a>

<a href={`${apiUrl}/generate/${info.token}`}>
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .pdv</button>
</a>

        
</div>

</div>
<div class="card-body">
<table className="table table-bordered t-nv">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>EXECUTANTE:</td>
    <td>
      
    <div onClick={()=>{
      this.setState({slcE:true})
    }} style={{
      padding:10,
      border:'solid 1px #ccc',
      borderRadius:5,
      cursor:'pointer'
    }}>{info.executante == undefined ? "Selecione:" : <>{this.state.data.executante.razao_social} - {this.formatCNPJ(this.state.data.executante.cnpj)}</>}</div>
      
      
  </td>
  </tr>
  <tr>
    <td>CONTRATANTE:</td>
    <td>{this.state.data.contratante.razao_social} - {this.state.data.contratante.cnpj}</td>
  </tr>

  <tr>
    <td>Rev:</td>
    <td>  <TextView type="text"
 code="drt6+62rse+2tr" 
 data={this.state.TextView}
/></td>
  </tr>


  <tr>
    <td>Número:</td>
    <td>  <TextView type="text"
 code="32+fg2+trhstr" 
 data={this.state.TextView}
/></td>
  </tr>


  <tr>
    <td>MÁQUINA / EQUIPAMENTO:</td>
    <td>  <TextView type="text"
 code="dffhdf6h1str" 
 data={this.state.TextView}
/></td>
  </tr>
  <tr>
    <td>ART VINCULADA: Nº</td>
    <td>

  <TextView type="text"
 code="dffg656h1str" 
 data={this.state.TextView}
/>
    </td> 
  </tr>
  <tr>
    <td>ESTADO:</td>
    <td>
    <TextView type="text"
 code="dffgutdrstr" 
 data={this.state.TextView}
/>
      </td> 
  </tr>
  <tr>
    <td>data:</td>
    <td>
    <TextView type="text"
 code="dfghfdfgdstr" 
 data={this.state.TextView}
/>
      </td> 
  </tr>
  <tr>
    <td>ARQUIVO DIGITAL:</td>
    <td>
    <ImageView code="grtssgfysh" data={this.state.ImageView}/> 
   </td>
  </tr>
  <tr>
    <td>Site:</td>
    <td>
    <TextView type="text"
 code="dfgdtyud+sdr51tr" 
 data={this.state.TextView}
/>
      </td>
  </tr>
</tbody>
      </table>
</div>

                        </div>
                    </div>

                    <div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>2 - NORMAS REFERENCIADAS</h3>
                           <Link to="/normas" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faList} /> Normas</Link>

                         </div>
                         <div class="card-body">
                         
             <div class="form-group">
           
                <select class="form-control" value="Selecione" onChange={(event)=>{
 const selectedId = parseInt(event.target.value, 10);

 const selectedItem = this.state.data.normas.find(item => item.id === selectedId);

 const check = this.state.data.doc_norma.some(usuario => usuario.titulo === selectedItem.titulo);

if(check){
  toast.warn(`A norma: ${selectedItem.titulo} já foi inserida!`)
  return false;
}




fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-normas/`, {
 method: 'POST',
 credentials: 'include',
 headers: {
   'Content-Type': 'application/json',
   'Authorization': 'Bearer '+Cookies.get("auth_token"),
 },
 body:JSON.stringify({ids:`${selectedId}`})
}).then(response => {
 
 if(response.status == 200){
  
 response.json().then(data=>{
        toast.success("Normas atualizadas com sucesso!")
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            doc_norma: [...prevState.data.doc_norma, selectedItem]
          }
        }));
 })
      }
})







                }} id="exampleSelect">
                   <option>Selecione:</option>
                    {this.state.data.normas.map((item)=><>
                    <option key={item.id}  value={item.id}>{item.titulo} - {item.cont}</option>
                    </>)}
                </select>
            </div>
            <br/>
       
  {this.state.data.doc_norma.length > 0 ? <>  <table className="table ntbl table-bordered">
        <thead>
          <tr>
            <th scope="col">NORMA</th>
            <th scope="col">REFERÊNCIA</th>
            <th scope="col">OPÇÃO</th>
          </tr>
          </thead>
          <tbody>
  {this.state.data.doc_norma.map((item)=><>
    <tr>
    <td>{item.titulo}</td>
    <td>{item.cont}</td>
    <td>     
      
    <button  class="btn btn-primary  ml-2" onClick={()=>{
            this.props.navigate(`normas/editar/doc/${window.location.pathname.split("/")[3]}/${item.id}`)     
    }}> <FontAwesomeIcon color='#fff' icon={faPen} /></button>


        <button  class="btn btn-danger  ml-2" onClick={async(e)=>{
                
                const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                  'Tem certeza?');
               if(result){
                
                
                
                fetch(`${apiUrl}/normas/editar/doc/remover/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body:JSON.stringify({id:item.id})
                }).then(response => {
                  this.setState((prevState) => ({
                    data: {
                      ...prevState.data,
                      doc_norma: prevState.data.doc_norma.filter(norma => norma.id !== item.id)
                    }
                  }));
                })
                
                
                
                
                
               
               }
           

                
                
              
                
         
                
              }}> <FontAwesomeIcon color='#fff' icon={faTrash} /></button>
              
              
              </td>
  </tr>
  </>)}

  </tbody>
  </table></> : <>Não há nenhuma norma salva!</>}

                         </div>
                 
                         </div>
 
              
                    </div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>3 - RESPONSÁVEIS TÉCNICOS</h3>
                           <Link to="/tecnicos" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faUser} /> Técnicos</Link>

                         </div>
                         <div class="card-body">
                         
             <div class="form-group">
               
                <select class="form-control" value="Selecione" onChange={(event)=>{
 const selectedId = parseInt(event.target.value, 10);
 const selectedItem = this.state.data.tecs.find(item => item.id === selectedId);

 const check = this.state.data.doc_tecs.some(usuario => usuario.id === selectedId);
if(check){
  toast.warn(`O tecnico: ${selectedItem.nome} já foi inserido!`)
  return false;
}


fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-tecs/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({ids:`${selectedId}`})
}).then(response => {
  if(response.status == 200){
   
  response.json().then(data=>{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        doc_tecs: [...prevState.data.doc_tecs, selectedItem]
      }
    }));
         toast.success("Normas atualizadas com sucesso!")
  })
       }
})







                }} id="exampleSelect">
                   <option>Selecione:</option>
                    {this.state.data.tecs.map((item)=><>
                    <option key={item.id}  value={item.id}>{item.nome} - {item.func}</option>
                    </>)}
                </select>
            </div>
            <br/>
       
  {this.state.data.doc_tecs.length > 0 ? <>  <table className="table ntbl table-bordered">
        <thead>
          <tr>
            <th scope="col">NOME</th>
            <th scope="col">FUNÇÃO</th>
            <th scope="col">OPÇÃO</th>
          </tr>
          </thead>
          <tbody>
  {this.state.data.doc_tecs.map((item)=><>
    <tr>
    <td>{item.nome}</td>
    <td>{item.func}</td>
    <td>       
       <button  class="btn btn-primary  ml-2" onClick={()=>{
             this.props.navigate(`/tecnicos/editar/doc/${window.location.pathname.split("/")[3]}/${item.id}`)     

              }}> <FontAwesomeIcon color='#fff' icon={faPen} /></button>


      <button  class="btn btn-danger  ml-2" onClick={async()=>{
                 
 const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                    'Tem certeza?');
                 if(result){
                  
                  
                  
                  fetch(`${apiUrl}/tecnico/doc/remover/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                    },
                    body:JSON.stringify({id:item.id})
                  }).then(response => {
                    this.setState((prevState) => ({
                      data: {
                        ...prevState.data,
                        doc_tecs: prevState.data.doc_tecs.filter(norma => norma.id !== item.id)
                      }
                    }));
                  })
                  
                  
                  
                  
                  
                 
                 }
             
  
                  
                  
                
                  
           
                  
                







             



              }}> <FontAwesomeIcon color='#fff' icon={faTrash} /></button>
              
              </td>
  </tr>
  </>)}

  </tbody>
  </table></> : <>Não há nenhum ténico salvo!</>}

                         </div>
                      
                         </div>
 
              
</div>


<div className="col-12 mt-3"  style={{display:'block'}}>
                    <div className="card">
                   

                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>4 - DOCUMENTAÇÃO DO EQUIPAMENTO</h3>
                          
                         </div>
                         <div class="card-body">

<table className="table ntbl table-bordered">
<thead> 
<tr>
            <th rowspan="2">DOCUMENTOS</th>
            <th colspan="3">STATUS</th>
            <th rowspan="2">OBSERVAÇÕES</th>
</tr>
<tr>
            <th>OK</th>
            <th>NÃO OK</th>
            <th>N.A.</th>
        </tr>
 </thead>
  <tbody>
  {[
  "ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA",
  "CHECKLIST DE MANUTENÇÃO PREVENTIVA",
  "CHECKLIST DE SEGURANÇA",
  "ART DIAGRAMA DE CIRCUITOS ELÉTRICOS",
  "DIAGRAMA HIDRÁULICO",
  "DIAGRAMA PNEUMÁTICO",
  "INSTRUÇÕES DE TRABALHO",
  "LAUDO DE ATERRAMENTO",
  "LAUDO DE TEMPO DE PARADA",
  "MANUAL DE INSTRUÇÕES DE MANUTENÇÃO",
  "MANUAL DE INSTRUÇÕES DE SEGURANÇA",
  "MANUAL DE INSTRUÇÕES OPERACIONAIS",
  "PLAQUETA DE IDENTIFICAÇÃO",
  "ART PROJETO MECÂNICO"
].map((item,index)=>{
var check = this.state.form.find(u=> u.e === `${index}`);
if(check != undefined){
var find = check;
}else{
var find = {}
}
return(<>

  <tr>
              <td>{item} 
  
  {index} 
  
  
              </td>
              <td>
              <label>
              
             <input type="radio"

              checked={find.marker === "0"}
          
              onChange={(e) => this.handleOpc(e, index)} name={`${index}`} value={0}/>
              </label>
                 
              </td>
  
              <td class="highlight">
            
              <input type="radio" 
              checked={find.marker === "1"}
              onChange={(e) => this.handleOpc(e, index)} name={`${index}`} value={1}/>
              </td>
  
              <td>
              <input onChange={(e) => this.handleOpc(e, index)} 
                      checked={find.marker === "2"}
              type="radio" name={`${index}`} value={2}/>
              </td>
              
              <td class="highlight">
             
                <textarea value={find.obs}
                onChange={(e)=>{

                 

                  this.setState(prevState => ({
                    form: prevState.form.map(item =>
                      item.e === `${index}` ? { ...item, obs:`${e.target.value}`} : item
                    )
                  }));


                  this.setState(prevState => ({
                    Opc: {
                      ...prevState.Opc,
                      [index]: {
                        ...prevState.Opc[index],
                        o:e.target.value
                      }
                    }
                  }));



                }}
                placeholder='Digite:'/>
              </td>
          </tr>
    </>)
})}
  </tbody>
</table>

                          
                         </div>
                         <div class="card-footer">
                         <button type="submit" class="btn btn-primary float-right ml-2" onClick={(e)=>{

var itens = this.state.Opc;

if(itens.length == 0){
  return;
}


e.target.classList.add("load")
console.log(itens)
fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/form/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({itens})
}).then(response => {
e.target.classList.remove("load")
toast.success("Salvo com sucesso!")
})




               }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
                         </div>
                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>5 - VISÃO GERAL DA MÁQUINA</h3>
                           <div className='custom-file'>
                           <button className='btn btn-primary btn-upl' onClick={()=>{
                            this.setState({upload5:true})
                            }}><FontAwesomeIcon color='#fff' icon={faUpload} />  Upload</button>
                           
                           {/*<input type="file" multiple onChange={this.handleFileChange} />*/}
                       
                           </div>
                            </div>
                         <div class="card-body">
<div class="prev-imgs">

{this.state.images.length > 0 ? <>

          {this.state.images.map((item) =>(<>
          <div class="pi">
          <img src={`https://api.hrnmachine.ind.br/img/`+item.img} alt="not loaded"/>
          <button onClick={async()=>{
             const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
              'Tem certeza?');
              if(result){
                fetch(`${apiUrl}/solicitante/deletar/imagem/${item.id}/`+window.location.pathname.split("/")[3], {
                  method: 'DELETE',
     
                 }).then(response => {
if(response.status == 200){
  
  this.setState({
    images:this.state.images.filter(e => e.id !== item.id)
  })


}
                 })
              }
          }}>
            <FontAwesomeIcon color='#fff' icon={faClose}/>
          </button>
          </div>
     
          </>))}
        
</> : <>Sem imagem!</>}






</div>
                         </div>
                         
                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>6 - Arranjo físico das instalações – Geral</h3>
                           
                            </div>
                         <div class="card-body">



<div class="form-group mt-2">
  <TextView type="text"
 code="dfasgkjohh" 
 data={this.state.TextView}
/>
                         <ImageView code="g5th4gh" data={this.state.ImageView}/> 


                         
                            </div>

                          

  <RiskAppreciationTable
  code="5s6df6"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

  <div class="form-group mt-2">
                          <label>AÇÕES PROPOSTAS COMO SUGESTÃO DE ADEQUAÇÃO:</label>
 <TextView type="text"
 code="204rtgd" 
 data={this.state.TextView}
/>

  
  </div>

  <RiskAppreciationTable
  code="5fdx56"

  

  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}

  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>

                         </div>
                         
                         </div>
 
        
</div>
      


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>7 - Instalações e dispositivos elétricos – Geral</h3>
                       
                       
                          <button className='btn btn-primary' onClick={()=>{
                            

                       
                              const id = toast.loading("Criando grupo")
   
                            fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image/`, {
                             method: 'POST',
                             credentials: 'include',
                             headers: {
                               'Content-Type': 'application/json',
                               'Authorization': 'Bearer '+Cookies.get("auth_token"),
                             },
                             body:JSON.stringify({d:new Date()})
                           }).then(response => {
                            toast.update(id, { render:"Grupo criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                         this.setState(prevState => ({
                              grupoi: [
                                ...prevState.grupoi,
                                { list: [] }  // Adiciona um novo item com imgs como array vazio
                              ]
                            })); 
                           })
   
   
   
   
                            



                          
                          }}>
                            <FontAwesomeIcon icon={faPlus} color='#fff'/> Adicionar grupo
                          </button>


                            </div>
                      

<div class="card-body">
  



  
<div class="form-group mt-2">
               
<TextView type="text"
 code="2354r65fdh" 
 data={this.state.TextView}
/>
  

                          
                                  </div>
                          

                          {this.state.grupoi.length > 0 ? <>


{this.state.grupoi.map((item,index)=>{
  var elemnt = item;
  var el = this;
  return(<>
  <>
  <div className="card" style={{marginTop:15}}>
                           

  <div class="card-header d-flex justify-content-between">
                           <h3>Grupo: {index+1}</h3>
                         <div style={{display:'flex'}}>




                          
                         
                          <button className='btn btn-primary' style={{
                            marginRight:10
                          }} onClick={async(e)=>{
                        
                        
                            this.setState({ModalOpen:true,itensModal:{item,index}})
                            return false;
                            const result = await CustomDialog(
                              <>
                
                        <div style={{margin:15}}>
                        <input type="text" class="form-control form-control-sm" placeholder='Titulo:' />
                        <br/>
                        <input class="form-control" type="file"  onChange={(event)=>{

const file = event.target.files[0];
    
if (file) {
  const isImage = file.type.startsWith('image/');
  if(isImage == false){
    event.target.value = '';
toast.error("Somente imagens são aceitas!")
    return false;
  }

}

                        }}/>
                      <button onClick={()=>{
                        this.props.dialog.close()
                      }}>
                        fehcar
                      </button>
                        </div>
                    
                     
                              </>,
                              {
                                title: 'Adicionar imagem',
                                showCloseIcon: true,
                              }
                            );
                          
    
    
    
                            /*
                            
                                
                             */    
                                  
    
    
    
    
    
                                  
    
    
    
                                  
                                }}>
                          <FontAwesomeIcon icon={faImage} color='#fff'/> Adicionar imagem</button>
                       
                          
                          <button className='btn btn-danger' onClick={async()=>{
              if(item.list.length > 0){
                    var r = await   Confirm(`Ao selecionar o opção ok esse grupo será deletado!`,'Atenção!')
                       }else{
                        
                        const id = toast.loading("Deletando")
                        fetch(`${apiUrl}/solicitante/${item.code}/delete/group-image/`, {
                          method: 'DELETE',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+Cookies.get("auth_token"),
                          },
                          body:JSON.stringify({d:new Date()})
                        }).then(response => {
                          if(response.status == 200){

                            toast.update(id, { render:"Grupo deletado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
               
                            this.setState({
                              grupoi:this.state.grupoi.filter(i => i.code !== item.code)
                              })
    
                          }
                        })





                       }
                      
                        }}>
                          <FontAwesomeIcon icon={faTrash} color='#fff'/> Deletar
                        </button>
                         </div>
                     

                            </div>

<div class="card-body">
<div className='gp-imgs'>




{this.state.grupoi[index].list.length > 0 ? <>
{this.state.grupoi[index].list.map((item)=><div class="view">
<img src={`${apiUrl}/img/${item.img}`} alt="imagem nao carregada!"/>
<div class="ac">
  <div class="arb">
  <button className='b1' onClick={async()=>{

const result = await CustomDialog(
  <>
  <ImageMarker imageSrc={`${apiUrl}/img/${item.img}`} item={item} onFinish={(data)=>{

this.setState((prevState) => {
  const updatedGroupi = [...prevState.grupoi];
  
  // Encontre o índice do grupo alvo
  const targetIndex = updatedGroupi.findIndex(e => e.code === elemnt.code);
  
  if (targetIndex !== -1) {
    // Encontre o índice do item dentro do grupo
    const itemIndex = updatedGroupi[targetIndex].list.findIndex(i => i.id === item.id);
    
    if (itemIndex !== -1) {
      // Atualize a imagem do item
      updatedGroupi[targetIndex].list[itemIndex] = {
        ...updatedGroupi[targetIndex].list[itemIndex],
        img: data.img // Substitua `newImage` pela nova imagem que você deseja definir
      };
    }
  }
  
  return { grupoi: updatedGroupi };
});

  }}/>
  </>,
  {
    title: 'Editar imagem',
    showCloseIcon: true,
  }
);




  
  }}>
  <FontAwesomeIcon color='#fff' icon={faPen}/>
</button>
<button  className='b2' onClick={async()=>{


console.log(item)

var r  = await Confirm('A exclusão da imagem é permanente e não pode ser desfeita','Atenção?')
 if(r){
  fetch(`${apiUrl}/solicitante/deletar/imagem/groupi/${item.id}/`, {
    method: 'DELETE',

   }).then(response => {

if(response.status == 200){
  this.setState((prevState) => {
    const updatedGroupi = [...prevState.grupoi];
    const targetIndex = updatedGroupi.findIndex(e => e.code === elemnt.code);
    if (targetIndex !== -1) {
      updatedGroupi[index].list = updatedGroupi[targetIndex].list.filter(i => i.id !== item.id);
    }
    return { grupoi: updatedGroupi };
  });
}
   

})



 }
}}>
  <FontAwesomeIcon color='#fff' icon={faTrash}/>
</button>
  </div>

</div>
</div>)}
</> : <>
Adicione uma imagem para ser exibida
</>}
</div>

</div>

                       </div>
</>
  </>)
}
)}
</> : <div style={{
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  flexDirection:'column',
  marginBottom:20
 
}}>
<img src={imagem} style={{width:200,height:200}}/>
<strong>Para visualizar adicione um grupo de imagens</strong>
</div>}


</div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>Diagrama </h3>
                           
                            </div>
                            <div class="card-body">
                     
<div class="diagram">
<Treetest save={this.state.diagram} node={{
  name: 'Start',
  children: [
    {
      name: 's1',
      children: [
        {
          name: 'f1',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
        { name: 'f2',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],

        },
      ],
    },
    {
      name: 's2',
      children: [
        {
          name: 'f1',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
        {
          name: 'f2',
          children: [
            { name: 'p1' },
            { name: 'p2' },
          ],
        },
      ],
    },
  ],
}}   onSelect={(e,type,id)=>{

  if(this.state.diagram.includes(`${e}-${id}`) == false){
  this.setState((prevState) => {


   
    if (prevState.diagram.includes(e)) {
      return prevState;
    } else {
      return {
        diagram: [...prevState.diagram, `${e}-${id}`]
      };
    }
  
  });

  }else{
 
    this.setState({
      diagram:this.state.diagram.filter(norma => norma !== `${e}-${id}`)
    })
  
  }

}}
allowSelect={true}/>
<div className='coluna'>
<div className={
["s1-1","f1-2","p1-3"].every((value, index) => value === this.state.diagram[index]) ? "a selected" : "a"
}>
  A
</div>
<div className={
["s1-1","f1-2","p2-4"].every((value, index) => value === this.state.diagram[index]) ||
["s1-1","f2-5","p1-6"].every((value, index) => value === this.state.diagram[index])
? "b selected" : "b"
}>
  B
</div>
<div className={
["s1-1","f2-5","p2-7"].every((value, index) => value === this.state.diagram[index]) ||
["s2-8","f1-9","p1-10"].every((value, index) => value === this.state.diagram[index])
? "c selected" : "c"
}>
  C
</div>

<div className={
["s2-8","f1-9","p2-11"].every((value, index) => value === this.state.diagram[index]) ||
["s2-8","f2-12","p1-13"].every((value, index) => value === this.state.diagram[index])
? "d selected" : "d"
}>
  D
</div>
<div className={
["s2-8","f2-12","p2-14"].every((value, index) => value === this.state.diagram[index]) ? "e selected" : "e"
}>
  E
</div>
</div>

</div>


<div><strong>Onde:</strong></div>
<div style={{padding:10}}>
<div><strong>1-</strong> Ponto de partida para a avaliação de redução de risco.</div>
<div><strong>L-</strong> Baixa contribuição para a redução do risco.</div>
<div><strong>H-</strong> Alta contribuição para a redução do risco.</div>
<div><strong>PLr-</strong> Performance Level requerido.</div>

</div>
<div style={{textAlign:'center'}}><strong>S – Severidade do ferimento</strong></div>
<div style={{padding:10}}>
<div><strong>S1-</strong>Ferimento leve (normalmente reversível)</div>
<div><strong>S2-</strong> Ferimento sério (normalmente irreversível) incluindo morte.</div>
</div>




<div style={{textAlign:'center'}}><strong>F – Frequência e/ou tempo de exposição ao perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>F1-</strong>Raro a relativamente frequente e/ou baixo tempo de exposição.</div>
<div><strong>F2-</strong> Frequente a contínuo e/ou tempo de exposição longo.</div>
</div>

<div style={{textAlign:'center'}}><strong>P – Possibilidade de evitar o perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>P1-</strong>Possível sob condições especificas.</div>
<div><strong>P2-</strong>Possível sob condições especificas.</div>
</div>



                            </div>
                         <div class="card-footer">
                         <button type="submit" class="btn btn-primary float-right ml-2" onClick={(e)=>{

if(this.state.diagram.length < 3){
  toast.error("Selecione todos itens do diagrama!")
  return false;
}
e.target.classList.add("load")
var d = this.state.diagram.join(",");
fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/diagrama/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({d})
}).then(response => {
e.target.classList.remove('load')
if(response.status == "200"){
  response.json().then(data=>{
    toast.success(data.r)
  })
}
}).catch(err=>{
  toast.error("Falha ao enviar!")
})





                         }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
                         </div>
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>8 - AÇÕES PROPOSTAS COMO SUGESTÃO DE
                           ADEQUAÇÃO</h3>
                       
                      <Link to="/galeria">
                      <button className='btn btn-primary'>
                        <FontAwesomeIcon icon={faImage} color='#fff'/> Imagens
                      </button>
                      </Link>
                     


                            </div>
                      

                            <div class="card-body">




<div className='itens-a8'>
  

<RiskAppreciationTable 
                            code="5fgf165h1f5"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DAS MELHORIAS"/>


<TextView type="input"
 code="35rs4hrt" 
 data={this.state.TextView}
/>
                            
<ImageView code="2111354er" galery="true" data={this.state.ImageView}/>  
                            
                           
                            <TextView type="text"
 code="32d4rhgr" 
 data={this.state.TextView}
/>



                          
                          
    
<TextView type="input"
 code="324drg35" 
 data={this.state.TextView}
/>


<RiskAppreciationTable 
                            code="58sdr4h"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>


<div></div>
<TextView type="input"
 code="324r685r89" 
 data={this.state.TextView}
/>


</div>
                           
                            
                            </div>



                         </div>
 
              
</div>




<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>9 - Dispositivo de partida, acionamento e parada – Geral</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

<RiskAppreciationTable 
code="54dfg6"
data={this.state.FormHrn}
resp={(value)=>{
  this.setState(prevState => ({
    AtMlhs: [...prevState.AtMlhs,value],
  }));
}}
title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>



<TextView type="text"
 code="xf5d4h" 
 data={this.state.TextView}
/>



                            <RiskAppreciationTable
                            code="fg56j4"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>

<TextView type="textarea"
 code="fgh5t2" 
 data={this.state.TextView}
/>

<TextView type="textarea"
 code="35dfghfgh54" 
 data={this.state.TextView}
/>
                    

                            <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView code="rth54g" data={this.state.ImageView}/>  
                            <ImageView code="c546hf" data={this.state.ImageView}/>  


                            </div>



<TextView type="input"
 code="5dzfg1" 
 data={this.state.TextView}
/>
                           
                            
                            </div>



                         </div>
 
              
</div>




<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>10 - Sistemas de Segurança</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
                           

      <ImageView code="5ddsf" data={this.state.ImageView}/>   





      <TextView type="input"
 code="fgth65" 
 data={this.state.TextView}
/>

                            <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView code="c6g5j4" data={this.state.ImageView}/>  
                            <ImageView code="nxdfh5" data={this.state.ImageView}/>  


                            </div>
                            


                            <TextView type="input"
 code="fg54jd" 
 data={this.state.TextView}
/>


                            </div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>11</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
                           
                    
                            <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView code="dsfy56n" data={this.state.ImageView}/>  
                            

                            </div>


                            <TextView type="input"
 code="f65g4ghn" 
 data={this.state.TextView}
/>
                           
                              <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView code="sdf65h4" data={this.state.ImageView}/>  
                            <ImageView code="5xdf4hb" data={this.state.ImageView}/>  
                            <ImageView code="987h65f" data={this.state.ImageView}/> 

                            </div>
                           
                           
                            </div>



                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>12</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
                           
                    
         <ImageView code="sr65h84" data={this.state.ImageView}/>




         <TextView type="input"
 code="35r57tv" 
 data={this.state.TextView}
/>



<ImageView code="d6f54gdh" data={this.state.ImageView}/>
        



<TextView type="input"
 code="65d4fh1gh" 
 data={this.state.TextView}
/>

<TextView type="textarea"
 code="3s54rh2" 
 data={this.state.TextView}
/>

                           
                            </div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>13</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
                           
                    
                            <div style={{
                            display:'flex',
                     
                            }}>
                            <ImageView code="w45f94" data={this.state.ImageView}/>  
                            <ImageView code="35d4fg" data={this.state.ImageView}/>  
                            <ImageView code="324das" data={this.state.ImageView}/>  
                            <ImageView code="32a41r" data={this.state.ImageView}/>
                            <ImageView code="kuyj65" data={this.state.ImageView}/>  
                         

                            </div>



                                                
                           
                            </div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>14</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
 <TextView type="input"
 code="654xh65" 
 data={this.state.TextView}
/>                  
                                                           
<ImageView code="sd542df" data={this.state.ImageView}/>  



<TextView type="input"
 code="mn16tyf5" 
 data={this.state.TextView}
/> 


<ImageView code="qwer46" data={this.state.ImageView}/>  


<TextView type="input"
 code="321rt6t" 
 data={this.state.TextView}
/> 



                                                
                           
                            </div>



                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>15</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                            
                           
                                    
<ImageView code="654srth" data={this.state.ImageView}/> 


<TextView type="input"
 code="e3455yf" 
 data={this.state.TextView}
/> 
                               
<ImageView code="zq351hd" data={this.state.ImageView}/>                       



                                                
                           
                            </div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>16</h3>
                       
                      


                            </div>
                      

                            <div class="card-body">

                        
                            <RiskAppreciationTable 
                            code="564dzx"
                            data={this.state.FormHrn}
                            resp={(value)=>{
                              this.setState(prevState => ({
                                AtMlhs: [...prevState.AtMlhs,value],
                              }));
                            }}
                            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>
                    
<TextView type="text"
 code="v3bh2m54" 
 data={this.state.TextView}
/>  


<ImageView code="654sdfv" data={this.state.ImageView}/>    


<TextView type="text"
 code="354h6fg5" 
 data={this.state.TextView}
/>  

                          
<ImageView code="654rtdsr6y5" data={this.state.ImageView}/>                         

<TextView type="text"
 code="324hgf7" 
 data={this.state.TextView}
/>  


<TextView type="text"
 code="s3d654ghsutr" 
 data={this.state.TextView}
/>  


                                                
                           
                            </div>



                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>17</h3>
      </div>
      <div class="card-body">
        
      <ImageView code="321t65grt" data={this.state.ImageView}/>



         <TextView type="text"
            code="3465465uy" 
            data={this.state.TextView}
            />

<TextView type="text"
            code="3a5rg35t" 
            data={this.state.TextView}
            />

<TextView type="text"
            code="u9y54sdf" 
            data={this.state.TextView}
            />
   
<div style={{display:'flex'}}>
<ImageView code="3546dfhf" data={this.state.ImageView}/>
<ImageView code="649685df" data={this.state.ImageView}/>
</div>

<TextView type="text"
            code="6546845y" 
            data={this.state.TextView}
  />

<ImageView code="2a654gf" data={this.state.ImageView}/>


         <TextView type="input"
            code="35df4h65" 
            data={this.state.TextView}
            />
        
      </div>
   </div>
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>18</h3>
      </div>
      <div class="card-body">
        
      <ImageView code="2164hdfg" data={this.state.ImageView}/>



         <TextView type="text"
            code="df65g465" 
            data={this.state.TextView}
            />

<ImageView code="324tyhxdf" data={this.state.ImageView}/>



         <TextView type="text"
            code="re354ty" 
            data={this.state.TextView}
            />
   

   <ImageView code="324retfd" data={this.state.ImageView}/>



<TextView type="text"
   code="e52ydfsyg" 
   data={this.state.TextView}
   />


   




<ImageView code="654fthjfg" data={this.state.ImageView}/>




      </div>
   </div>
</div>










<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>19</h3>
      </div>
      <div class="card-body">
        
      <TextView type="input"
            code="324tydu6" 
            data={this.state.TextView}
      />

<TextView type="input"
            code="324ruy5ds" 
            data={this.state.TextView}
      />

<TextView type="text"
            code="3254dfuf" 
            data={this.state.TextView}
      />

<TextView type="input"
            code="3254sdr65" 
            data={this.state.TextView}
      />


<TextView type="input"
            code="21rd65yt" 
            data={this.state.TextView}
      />



      <ImageView code="324fghfsdt" data={this.state.ImageView}/>

      <TextView type="input"
            code="32srtysf" 
            data={this.state.TextView}
      />
<ImageView code="6354rdshdt" data={this.state.ImageView}/>
         <TextView type="input"
            code="32dgdfgt" 
            data={this.state.TextView}
            />


      </div>
   </div>
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>20</h3>
      </div>
      <div class="card-body">
        
<ImageView code="32135tdr" data={this.state.ImageView}/>

<TextView type="text"
            code="3254rdahn" 
            data={this.state.TextView}
      />


<TextView  type="input"
            code="354sdrg56" 
            data={this.state.TextView}
      />

     




      </div>
   </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>21 - A máquina conta com botões de parada de emergência sem identificação.</h3>
      </div>
      <div class="card-body">

      <RiskAppreciationTable
  code="66zdr48y"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
 <TextView type="input"
            code="2f4gh3er" 
            data={this.state.TextView}
      />

<RiskAppreciationTable
  code="654srgr"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>


<TextView type="textarea"
            code="324df657ty" 
            data={this.state.TextView}
/>

<TextView type="textarea"
            code="65t4yhey" 
            data={this.state.TextView}
/>


<TextView type="input"
            code="054drt2s4" 
            data={this.state.TextView}
/>




<div style={{display:'flex'}}>
<ImageView code="3254r2e" data={this.state.ImageView}/>

</div>
     
     

<RiskAppreciationTable
  code="365rterd"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
   


      </div>
   </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>22 - Manutenção, inspeção, preparação, ajuste, reparo e limpeza.</h3>
      </div>
      <div class="card-body">

      <TextView type="input"
            code="3s3rg54" 
            data={this.state.TextView}
      />


      <ImageView code="6546hfr" data={this.state.ImageView}/>
    

    <RiskAppreciationTable
  code="98rth1"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

   


      </div>
   </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>23 - Sinalização, manuais, procedimentos e capacitação</h3>
      </div>
      <div class="card-body">

      <TextView type="text"
            code="fg564gh5" 
            data={this.state.TextView}
      />


      <ImageView code="354sery1" data={this.state.ImageView}/>
    

    <RiskAppreciationTable
  code="324rd321"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>

<TextView type="text"
            code="naer1654" 
            data={this.state.TextView}
      />

<RiskAppreciationTable
  code="24136rt"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>



      </div>
   </div>
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         <h3>24</h3>
      </div>
      <div class="card-body">

      <TextView type="text"
            code="3241654sa" 
            data={this.state.TextView}
      />

<div style={{display:'flex'}}>
<ImageView code="324fthth" data={this.state.ImageView}/>
</div>
     
    

    <RiskAppreciationTable
  code="02654r4r"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>

<span>Data:</span>
<TextView type="input"
            code="35f4djn" 
            data={this.state.TextView}
      />


      </div>
   </div>
</div>



                </div>
                









            </div>
        </main>





        <StaticDialog
        isOpen={this.state.slcE}
        title="Selecione o executante:"
        onAfterClose={(result) => {
         
        this.setState({slcE:false,itemE:undefined})
        }}
      >
       <div style={{padding:10}}>
       <select style={{width:'100%'}} onChange={(e)=>{
        var itemE  = e.target.value;
        if(itemE == ""){
          this.setState({itemE:undefined})
          return;
        }
this.setState({itemE})
       }}>
         <option value="">Selecione:</option>
          {this.state.data.exs.map((item)=><>
          
          <option value={item.cnpj}>{item.razao_social} - {this.formatCNPJ(item.cnpj)}</option>
          </>)}
        </select>
       </div>
        <hr/>



        <div class="d-flex justify-content-end" style={{margin:10}}>
  <button type="button" class="btn btn-primary" disabled={this.state.itemE == undefined ? true : false} onClick={(e)=>{
    e.target.classList.add('load')
    fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-executante/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      },
      body:JSON.stringify({itemE:this.state.itemE})
     }).then(response => {
if(response.status == 200){

  var it = this.state.data.exs.find(item => item.cnpj === this.state.itemE);







  this.setState(prevState => ({
    data: {
      ...prevState.data,
      executante: {
        ...prevState.data.executante,
        razao_social:it.razao_social,
        cnpj:it.cnpj
      }
    }
  }));

  this.setState(prevState => ({
    data: {
      ...prevState.data,
      info: {
        ...prevState.data.info,
        executante:it.cnpj
      }
    }
  }));

  e.target.classList.remove('load')
  toast.success('Sucesso!')
  this.setState({slcE:false,itemE:undefined})
}
     }).catch(()=>{
      e.target.classList.remove('load')
      toast.error('Falha na comunicação')
     })
  }}>
    <FontAwesomeIcon icon={faSave}/> Salvar
  </button>
</div>

      </StaticDialog>




        <StaticDialog
        isOpen={this.state.upload5}
        title="Adicionar imagem"
        onAfterClose={(result) => {
         
        this.setState({ImgPrev:undefined,upload5:false,upl7:[]})
        }}
      >
         <div style={{margin:15}}>
<div class="area-8">
{this.state.ImgPrev != undefined ? <>

  <div class="preview"  >
  <div class="edit">
  <input type="file" onChange={(e)=>this.AddImg(e)}/>
  <FontAwesomeIcon icon={faPen} color='#fff'/>
  </div>

  <img  src={`${this.state.ImgPrev}`} />
  </div>

</> : <>
  <div class="add">
                        <input class="form-control" type="file"  onChange={(e)=>this.AddImg(e)}/>
                        <FontAwesomeIcon icon={faPlus} color='#282828'/>
                        </div>
</>}
                    

                        
</div>

         </div>
<hr/>


<div class="d-flex justify-content-end" style={{margin:10}}>
  <button type="button" class="btn btn-primary" disabled={this.state.upl7.length == 0 ? true : false} onClick={()=>{
   var file = this.state.upl7;


if(file.length == 0){
  return;
}
const id = toast.loading("Enviando")

   const formData = new FormData();
     if (file) {
       Array.from(file).forEach((file) => {
         formData.append('images', file);
       });
 
 
       fetch(`${apiUrl}/solicitante/imagens/`+window.location.pathname.split("/")[3], {
         method: 'POST',
         body: formData
        }).then(response => {
         if(response.status == 200){
           response.json().then(data=>{
             
           
            toast.update(id, { render:"Salvo!", type: "success", isLoading: false,autoClose: 3000});
                   
 
             this.setState((prevState) => {
               
               const novaLista = [data[0], ...prevState.images];
             
      
               return {
               images:novaLista
               };
             });
 this.setState({upload5:false,ImgPrev:undefined,upl7:[]})
 
 
 
           })
         }
        })

      }
  }}>
    <FontAwesomeIcon icon={faUpload}/> 
     Upload</button>
</div>



      </StaticDialog>






        <StaticDialog
        isOpen={this.state.ModalOpen}
        title="Adicionar imagem"
        onAfterClose={(result) => {
         
        this.setState({ModalOpen:false,txt7:'',ImgPrev:undefined})
        }}
      >

                       

          <div style={{margin:15}}>
              
                       
                       
                        <input type="text" class="form-control form-control-sm" 
                
                      onChange={(e)=>{
                          
                          this.setState({txt7:e.target.value})
                        }}
                        value={this.state.txt7} placeholder='Titulo:' />
                        <br/>


                        <div class="area-8">
{this.state.ImgPrev != undefined ? <>

  <div class="preview"  >
  <div class="edit">
  <input type="file" onChange={(e)=>this.AddImg(e)}/>
  <FontAwesomeIcon icon={faPen} color='#fff'/>
  </div>

  <img  src={`${this.state.ImgPrev}`} />
  </div>

</> : <>
  <div class="add">
                        <input class="form-control" type="file"  onChange={(e)=>this.AddImg(e)}/>
                        <FontAwesomeIcon icon={faPlus} color='#282828'/>
                        </div>
</>}
                    

                        
</div> 





<br/>
<div class="d-flex justify-content-end">
  <button type="button" class="btn btn-primary" onClick={()=>{

    const formData = new FormData();


    formData.append('imagem', this.state.upl7[0]);
    formData.append('titulo',this.state.txt7);
    fetch(`${apiUrl}/solicitante/add/grupoi/${window.location.pathname.split("/")[3]}/${this.state.itensModal.item.code}`, {
      method: 'POST',
      body: formData
     }).then(response => {
      if(response.status == 200){
        response.json().then(data=>{
          this.setState((prevState) => {
            const newGrupois = [...prevState.grupoi];
            newGrupois[this.state.itensModal.index].list.push({
              id:data.id,
              img:data.img
            });
            return { grupoi: newGrupois };
          });
          this.setState({ModalOpen:false})
          toast.success("Imagem enviada com sucesso!")
        })
      }
     })
  }}>
    <FontAwesomeIcon icon={faUpload}/> 
     Upload</button>
</div>
                        </div>

      </StaticDialog>
        
<ToastContainer/>
        </>
  
    );
  }
}

export default PrevCot;