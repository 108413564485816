import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faDownload,faArrowLeft, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../config';
import p404 from "../../images/undraw_text_files_au1q.png"
class PrevExe extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     data:[]
    };
  }

  componentDidMount() {
    document.title = "Pré-visualização do executante";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({
    data,
    Loader:false
  })
})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
      })
}

 


  render() {
    var {data} = this.state;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.p404 == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Pré-visualização do executante</button>
          
        </div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    
                    <div className="col-12 mt-3">
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>Dados do executante:</h3>
   <Link to={`/${window.location.pathname.split("/")[1]}/edit/${data.token}`}>
   <button class="btn btn-xs btn-primary">
  <FontAwesomeIcon color='#fff' icon={faPen} /> Editar
  </button>
   </Link>                         

</div>
<div class="card-body">
<table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">PREVISÃO DE DESPESAS</th>
            <th scope="col">REFERENCIA</th>
          </tr>
        </thead>
        <tbody>
      <tr>
            <td>LOGO:</td>
            <td><span>{data.logo!= undefined ? <>
    <img class="emp-logo" src={`${apiUrl}/img/${data.logo}`}/>
    </> : "Não informado!"}</span></td>
          </tr>
          <tr>
            <td>RAZÃO SOCIAL:</td>
            <td>{data.razao_social}</td>
                </tr>
          <tr>
            <td>CNPJ:</td>
            <td>{data.cnpj}</td>
          </tr>
          <tr>
            <td>ENDEREÇO:</td>
            <td>{data.endereco}</td>
          </tr>
          <tr>
            <td>ESTADO:</td>
            <td>
            {data.estado}
            </td>
          </tr>
          
         
          <tr>
            <td>CEP:</td>
            <td>{data.cep}</td>
          </tr>
          <tr>
            <td>TELEFONE:</td>
            <td>{data.telefone}</td>
          </tr>
          <tr>
            <td>E-MAIL:</td>
            <td>{data.email}</td>
          </tr>
        </tbody>
      </table>
</div>
<div class="card-footer">
               <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}><FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>
<a href={`${apiUrl}/cotacao/baixar/${data.token}`}>
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar</button>
 
</a>
              </div>
                        </div>
                    </div>
                </div>
                









            </div>
        </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default PrevExe;