import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faArrowLeft,faSave, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import p404 from "../../../images/undraw_text_files_au1q.png"

class EditCot extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     cotacao:[]
    
    };
   
  }

  componentDidMount() {
    document.title = "Editar laudo";
    var url  = window.location.href;
    const match = url.match(/edit\/(.*)$/);
    fetch(`${apiUrl}/solicitante/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
this.setState({cotacao:data,Loader:false})
})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
      })
}

 
handleInputChange = (e) => {
  const { name, value } = e.target;
  this.setState((prevState) => ({
    cotacao: {
      ...prevState.cotacao,
      [name]: value
    }
  }));
}

  render() {
    var cotacao = this.state.cotacao;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.p404 == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Editar laudo </button>
          
       </div></header>
       
        <main>
      <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="row">
            <div className="col-12">
         
                <div className="card">
                  <div className="card-header">
                    <h3>Editar laudo:</h3>
                  </div>
                  <div className="card-body">
          
            
                  <div className="card-body">
                  <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">TITULO</th>
          <th scope="col">REFERENCIA</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td>EXECUTANTE:</td>
            <td><input type="text" className="form-control" placeholder="Digite o executante" value={cotacao.executante} name="executante" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>CONTRATANTE:</td>
            <td><input type="text" className="form-control" placeholder="Digite o contratante" value={cotacao.contratante} name="contratante" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>MÁQUINA / EQUIPAMENTO:</td>
            <td><input type="text" className="form-control" placeholder="Digite a máquina/equipamento" value={cotacao.maquina} name="maquina" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ART VINCULADA: Nº</td>
            <td><input type="text" className="form-control" placeholder="Digite a art vinculada" value={cotacao.art_vinculada} name="art_vinculada" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>ESTADO:</td>
            <td>
              <input type="text" className="form-control" placeholder="Digite o estado" value={cotacao.estado} name="estado" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>DATA:</td>
            <td><input type="date" className="form-control" placeholder="Digite a data" value={cotacao.data} name="data" onChange={this.handleInputChange} /></td>
          </tr>
          <tr>
            <td>QRCODE:</td>
            <td>
            <input class="form-control" type="file"    onChange={this.handleImageChange}  id="formFile"/>
            </td>
          </tr>
          <tr>
            <td>Site:</td>
            <td><input type="text" className="form-control" placeholder="Digite o site" value={cotacao.site} name="site" onChange={this.handleInputChange} /></td>
          </tr>
         
        </tbody>
    </table>
      </div>
                   
                   
                   
                  </div>
                  <div className="card-footer">
                   

                  <button type="submit" class="btn btn-danger float-left ml-2" onClick={()=>{
                  this.props.navigate(-1)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Voltar</button>



                    <button type="submit" className="btn btn-primary float-right ml-2" onClick={()=>{
                              const id = toast.loading("Enviando...")
                            var url  = window.location.href;
                            const match = url.match(/edit\/(.*)$/);
                            delete cotacao.qr_code;
                            fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar/`, {
                            method: 'POST',
                            credentials: 'include',
                            headers: {
                              'Content-Type': 'application/json',
                              'Authorization': 'Bearer '+Cookies.get("auth_token"),
                            },
                            body:JSON.stringify({cotacao})
                          }).then(response => {
                            if(response.status == 200){
                            response.json().then(data=>{
                              toast.update(id, { render:"Solicitante atualizado com sucesso!", type: "success", isLoading: false,autoClose: 3000});

                              this.props.navigate(`/solicitante/preview/${data.token}`)        
                            })
                                 }
                          })
                    }}>
                     <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar
                    </button>
                  </div>
                </div>
          
            </div>
          </div>
        </div>
      </div>
    </main>

        
<ToastContainer/>
        </>
  
    );
  }
}

export default EditCot;