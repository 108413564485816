import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';

import p404 from "../../../images/undraw_text_files_au1q.png"
import { apiUrl } from '../../../config';
class CustoDeOrigem extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     custo_de_origem:{
        air_freight_service: '',
        special_handling_thc_import_fee: '',
        entry_customs_clearance: '',
        handling: '',
        messenger_fee: '',
        pick_up_airport_pickup: '',
     }
    };
  }

  componentDidMount() {
    document.title = "Custo de origem";

    fetch(`${apiUrl}/cotacao/preview/${window.location.pathname.split("/")[3]}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      }
    }).then(response => {
if(response.status == 200){
response.json().then(data=>{
this.setState({
custo_de_origem:data.custo_de_origem,
Loader:false})
})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
    })
}


handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log('aaa')
    const numericValue = value.replace(/[^0-9,.]/g, '');
    this.setState(prevState => ({
      custo_de_origem: {
        ...prevState.custo_de_origem,
        [name]: numericValue
      }
    }));
  }


  render() {
  var custo_de_origem = this.state.custo_de_origem;
  if(this.state.Loader == true){
      return(<>
      <div className="loader-container">
      <div class="loader"></div>
      </div>
     
      </>)
  }
  if(this.state.p404 == true){
    return(<>
    <div class="p404">
    
      <img src={p404}/>
     <p>Desculpe, Esse documento não foi encontrado.</p>
     <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
             }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
    
   
    
    </div>
    </>)
  }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Custo de origem</h2></div></header>
        <main>
          

          
          
          
        <div className="py-12">
<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <h3>CUSTO DE ORIGEM</h3>
            </div>
            <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">SERVIÇO</th>
          <th scope="col">US$</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td>Air Freight Service</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="air_freight_service"
                placeholder="Digite o serviço de frete aéreo"
                value={custo_de_origem.air_freight_service}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
          <tr>
            <td>Special Handling (THC) Import Fee</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="special_handling_thc_import_fee"
                placeholder="Digite a taxa de manuseio especial (THC)"
                value={custo_de_origem.special_handling_thc_import_fee}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
          <tr>
            <td>Entry Customs Clearance</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="entry_customs_clearance"
                placeholder="Digite a taxa de desembaraço aduaneiro"
                value={custo_de_origem.entry_customs_clearance}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
          <tr>
            <td>Handling</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="handling"
                placeholder="Digite a taxa de manuseio"
                value={custo_de_origem.handling}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
          <tr>
            <td>Messenger Fee</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="messenger_fee"
                placeholder="Digite a taxa de mensageiro"
                value={custo_de_origem.messenger_fee}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
          <tr>
            <td>Pick Up Airport Pickup</td>
            <td>
              <input
                type="text"
                className="form-control"
                name="pick_up_airport_pickup"
                placeholder="Digite a taxa de coleta no aeroporto"
                value={custo_de_origem.pick_up_airport_pickup}
                onChange={this.handleInputChange}
              />
            </td>
          </tr>
        </tbody>
    </table>
    <div class="card-footer">
      
    <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     const id = toast.loading("Enviando...")
     var custo_de_origem = this.state.custo_de_origem;
     fetch(`${apiUrl}/cotacao/custo-de-origem/${window.location.pathname.split("/")[3]}/`, {
         method: 'POST',
         credentials: 'include',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer '+Cookies.get("auth_token"),
         },
         body:JSON.stringify({custo_de_origem}),
     
     }).then(response => {
     if(response.status == 200){
         response.json().then(data=>{
             toast.update(id, { render:"Cadastro realizado com sucesso!", type: "sucess", isLoading: false,autoClose: 3000});

         this.props.navigate(`/cotacao/preview/${data.token}`)
         })
     }
     if(response.status == 422){
         response.json().then(data=>{
             toast.update(id, { render:data.error, type: "error", isLoading: false,autoClose: 3000});

         })
     }
     }).catch(err=>{
     
     })
    }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
    </div>
         </div>

      </div>
  
</div>
</div>
         
      








         </main>
<ToastContainer/>
        </>
  
    );
  }
}

export default CustoDeOrigem;