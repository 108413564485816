import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faDownload, faPen, faArrowLeft, faAngleLeft,} from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import InputMask from 'react-input-mask';
import p404 from "../../../images/undraw_text_files_au1q.png"
import { Confirm } from 'react-st-modal';

class EditTecnicoDoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Loader:true,
        error:false,
        tecnico:[]
      };
  }
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  }

  componentDidMount() {
    document.title = "Editar técnico";
    var path = window.location.pathname.split("/");
    fetch(`${apiUrl}/tecnico/editar/doc/${path[4]}/${path[5]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({tecnico:data,Loader:false})
})
}
if(response.status == 404){
    this.setState({error:true,Loader:false})
}
      })
}

handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      tecnico: {
        ...prevState.tecnico,
        [name]: value
      }
    }));
  };



  render() {
    const { tecnico } = this.state;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  {document.title}</button>
        </div></header>
       
        <main>

     <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      
      <div class="row">
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">
<h4>DADOS DO TÉCNICO</h4>
</div>

               <div class="card-body">
               <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Nome</th>
          <th scope="col">Função</th>
        </tr>
      </thead>
      <tbody>
            <tr>
            <td><input type="text" className="form-control" placeholder="Digite o titulo:" name="nome" value={tecnico.nome} onChange={this.handleChange} /></td>
    
              <td><textarea type="text" className="form-control" placeholder="Digite a descrição:" name="func" value={tecnico.func} onChange={this.handleChange} /></td>
            </tr>
           
          </tbody>
    </table>
   
               </div>
             

               <div class="card-footer">
               <button type="button" class="btn btn-primary float-right ml-2" onClick={()=>{
                       
                     var p =    window.location.pathname.split("/")
                fetch(`${apiUrl}/tecnico/atualizar/doc/${p[4]}/${p[5]}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                    },
                    body:JSON.stringify({tecnico}),
                
                }).then(response => {
                if(response.status == 200){
                    response.json().then(data=>{
                       
                      
                    this.props.navigate(`/solicitante/preview/${data.token}`)

                    })
                }
                if(response.status == 422){
                    response.json().then(data=>{
                       
                    })
                }
                }).catch(err=>{
                 
                })



               }}> <FontAwesomeIcon color='#fff' icon={faSave} />  Atualizar</button>
               </div>


            </div>
         </div>
      </div>
   </div>
      </div>
      </div>
      
     </div>
     
     </div>


        </main>

        

        </>
  
    );
  }
}

export default EditTecnicoDoc;