import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faSave } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../config';
import p404 from "../../images/undraw_text_files_au1q.png"
class FreteInternacional extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     frete_internacional:[]
    };
  }

  componentDidMount() {
    document.title = "Frete internacional";

    fetch(`${apiUrl}/cotacao/preview/${window.location.pathname.split("/")[3]}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      }
    }).then(response => {
if(response.status == 200){
response.json().then(data=>{
this.setState({frete_internacional:data.frete_internacional,Loader:false})
})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
    })
}

handleInputChange = (event) => {
  const { name, value } = event.target;
  this.setState(prevState => ({
    frete_internacional: {
      ...prevState.frete_internacional,
      [name]: value
    }
  }));
};


  render() {
  var frete_internacional = this.state.frete_internacional;
  if(this.state.Loader == true){
      return(<>
      <div className="loader-container">
      <div class="loader"></div>
      </div>
     
      </>)
  }
  if(this.state.p404 == true){
    return(<>
    <div class="p404">
    
      <img src={p404}/>
     <p>Desculpe, Esse documento não foi encontrado.</p>
     <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
             }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
    
   
    
    </div>
    </>)
  }
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8"><h2 class="font-semibold text-xl text-gray-800 leading-tight">Frete internacional</h2></div></header>
        <main>
          
          
        <div className="py-12">

        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
         <div class="card">
            <div class="card-header">
               <h3>FRETE INTERNACIONAL</h3>
            </div>
            
       <div class="card-body">
     <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">SERVIÇO</th>
          <th scope="col">MOEDA</th>
          <th scope="col">TARIFA</th>
          <th scope="col">UNIDADE</th>
          <th scope="col">TOTAL</th>
        </tr>
      </thead>
      <tbody>
<tr>
<td><input type="text" name="servico" value={frete_internacional.servico} onChange={this.handleInputChange} class="form-control" placeholder="Digite o serviço"/></td>
<td><input type="text" name="moeda" value={frete_internacional.moeda} onChange={this.handleInputChange} class="form-control" placeholder="Digite a moeda"/></td>
<td><input type="text" name="tarifa" value={frete_internacional.tarifa} onChange={this.handleInputChange} class="form-control" placeholder="Digite a tarifa"/></td>
<td><input type="text" name="unidade" value={frete_internacional.unidade} onChange={this.handleInputChange} class="form-control" placeholder="Digite a unidade"/></td>
<td><input type="text" name="total" value={frete_internacional.total} onChange={this.handleInputChange} class="form-control" placeholder="Digite o total"/></td>
</tr>

<tr>
<td></td>
<td></td>
<td></td>
<td>TOTAL USD</td>
<td><input type="text" name="usd" value={frete_internacional.usd} onChange={this.handleInputChange} class="form-control" placeholder="Digite o total"/></td>

</tr>

<tr>
<td></td>
<td></td>
<td></td>
<td>TOTAL EUR</td>
<td><input type="text" name="eur" value={frete_internacional.eur} onChange={this.handleInputChange} class="form-control" placeholder="Digite o total"/></td>

</tr>



      </tbody>
      </table>
      </div>
      <div class="card-footer">
    <button type="submit" class="btn btn-primary float-right ml-2" onClick={()=>{
     const id = toast.loading("Enviando...")
     var frete_internacional = this.state.frete_internacional;
     fetch(`${apiUrl}/cotacao/frete-internacional/${window.location.pathname.split("/")[3]}/`, {
         method: 'POST',
         credentials: 'include',
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer '+Cookies.get("auth_token"),
         },
         body:JSON.stringify({frete_internacional}),
     
     }).then(response => {
     if(response.status == 200){
         response.json().then(data=>{
             toast.update(id, { render:"Cadastro realizado com sucesso!", type: "sucess", isLoading: false,autoClose: 3000});

         this.props.navigate(`/cotacao/preview/${data.token}`)
         })
     }
     if(response.status == 422){
         response.json().then(data=>{
             toast.update(id, { render:data.error, type: "error", isLoading: false,autoClose: 3000});

         })
     }
     }).catch(err=>{
     
     })
    }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
    </div>
         </div>
      </div>
  
</div>

</div>  
          
          
          
          
         
      








         </main>
<ToastContainer/>
        </>
  
    );
  }
}

export default FreteInternacional;