import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./g.css"
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave,faDownload, faPen, faArrowLeft, faPrint, faAngleLeft, faImage, faUpload, faPlus, faClose,} from '@fortawesome/free-solid-svg-icons';

import { apiUrl } from '../../config';
import { Confirm, StaticDialog } from 'react-st-modal';
class Galeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Loader:true,
        modal:false,
        titulo:'',
        selectedFile: null,
        imgUrl:''
      };
  }

  componentDidMount() {
    document.title = "Minha galeria";
    fetch(`${apiUrl}/galery/list`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({
    data,
    Loader:false
    })
})
}
if(response.status == 404){
    this.setState({p404:true,Loader:false})
}
      })
}

 


  render() {

    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }


  
    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Galeria</button>
        </div>
        </header>
       
        <main>
        <div className="py-12">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>Imagens</h3>
                       
                      <Link to="/galeria">
                      <button className='btn btn-primary' onClick={()=>{
                        this.setState({modal:true})
                      }}>
                        <FontAwesomeIcon icon={faUpload} color='#fff'/> Upload
                      </button>
                      </Link>
                     


                            </div>
                      

                            <div class="card-body">


<div class="area-galeria">


{this.state.data.length > 0 ? <>
{this.state.data.map((item)=><div class="image">
<button onClick={async()=>{
  const isConfirm = await Confirm(
    'Se confirmar, a imagem será excluída permanentemente. Deseja continuar?',
    'Confirmar Exclusão'
  );
if(isConfirm){

    fetch(`${apiUrl}/galery/delete/${item.id}`, {
        method: 'DELETE',

       }).then(response => {
if(response.status == 200){
    const updatedData = this.state.data.filter(i => i.id !== item.id);
    this.setState({ data: updatedData });
}

})


}
}}>
    <FontAwesomeIcon icon={faClose} color='#fff'/>
</button>
<img src={`${apiUrl}/img/${item.img}`}/>
</div>)}
</> : <>
Não há imagens
</>}
</div>

                            </div>



                         </div>
 
              
</div>





</div></div>




        </main>

        
<ToastContainer/>

<StaticDialog
        isOpen={this.state.modal}
        title="Upload de imagem"
        onAfterClose={(result) => {
         
   this.setState({modal:false,titulo:'',selectedFile:null})
        }}
      >

<div style={{padding:10}}>
<input type='text' style={{
    width:'100%',
    height:30
}} value={this.state.titulo} onChange={(e)=>{
    this.setState({titulo:e.target.value})
}} placeholder='Titulo:'/>

<div class="area-8" style={{marginBottom:-15}} code="2111354er">
  {this.state.selectedFile == undefined ? <>
    <div class="add" code="2111354er">
     <input type='file' accept="image/*" onChange={(e)=>{
const file = e.target.files[0];

if (file && file.type.startsWith('image/')) {
    this.setState({selectedFile:file})
    if (file) {
      const imgUrl= URL.createObjectURL(file); 
      this.setState({ imgUrl });
    }
}else{

    toast.error('Por favor, selecione um arquivo de imagem válido.')
    return false;
}





     }}/>
     <FontAwesomeIcon icon={faPlus} />
      
   </div>
  </>  : <>
<div className='preview'>
<div className='fx' onClick={()=>{
    this.setState({selectedFile:null})
}}>
    <FontAwesomeIcon icon={faClose} color='#fff'/>
</div>
<img src={this.state.imgUrl}/>
</div>
  </>}
</div>



</div>

<div class="d-flex justify-content-end" style={{margin:10}}>

      <button class="btn btn-primary" onClick={()=>{
  const id = toast.loading("Enviando imagem")

  const formData = new FormData();


  formData.append('imagem', this.state.selectedFile);
  formData.append('title',this.state.titulo);
  fetch(`${apiUrl}/galery/add`, {
    method: 'POST',
    body: formData
   }).then(response => {
    if(response.status == 200){
      response.json().then(data=>{
        



        const updatedData = [{img:data.img,id:data.id}, ...this.state.data];
  
        this.setState({data: updatedData,modal:false,titulo:'',selectedFile:null})

        toast.update(id, { render:"Imagem salva", type: "success", isLoading: false,autoClose: 3000});
      })
    }
}).catch(data=>{
    toast.update(id, { render:"Erro no processamento!", type: "error", isLoading: false,autoClose: 3000});
})
      }} disabled={this.state.selectedFile == null ? true : false}><FontAwesomeIcon style={{marginRight:10}} icon={faSave} color='#fff'/>
       Salvar
      </button>
</div>


      </StaticDialog>




        </>
  
    );
  }
}

export default Galeria;