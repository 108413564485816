import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

import Modal from '../../Comps/Modal';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faUpload, faPlus, faArrowLeft, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

import p404 from "../../images/undraw_text_files_au1q.png"
import { apiUrl } from '../../config';
class LaudoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader:true,
      error:false,
      data:[],
      Ptxt:'',
      Tpsq:'Nome',
      search:[],
      busca:false
    };
  }
  formatarData(data) {
    const dateObj = new Date(data);
    const dia = String(dateObj.getDate()).padStart(2, '0');
    const mes = String(dateObj.getMonth() + 1).padStart(2, '0'); // Mês começa do 0
    const ano = dateObj.getFullYear();
    const horas = String(dateObj.getHours()).padStart(2, '0');
    const minutos = String(dateObj.getMinutes()).padStart(2, '0');
    const segundos = String(dateObj.getSeconds()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  }
  componentDidMount() {
    document.title = "Laudos";
    fetch(`${apiUrl}/laudo-tecnico/${window.location.pathname.split("/")[2]}/list`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
if(response.status == 404){
   this.setState({error:true,Loader:false})
}
      })
}

 

_Busca = () =>{
   fetch(`${apiUrl}/busca-cotacao/${window.location.pathname.split("/")[2]}/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      },
      body:JSON.stringify({
         Tpsq:this.state.Tpsq,
         Ptxt:this.state.Ptxt
      })
    }).then(response => {
     if(response.status == 200){
      response.json().then(search=>{
         this.setState({busca:true,search})
      })
   
     }
    })
   
}

  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.error == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
         
      <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} /> Laudos</button>
    </div></header>
      <main>
      <div class="py-1">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      
      <div class="row">
 
      </div>


      <div class="row">
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">
<div className='area-busca'>


<input type="text" class="form-control" style={{borderLeft:'solid 1px #ccc'}} placeholder="Digite:" name="nome" onKeyUp={(e)=>{
  if(e.keyCode == 13){
   this._Busca()
  }
if(e.target.value.length == 0){
this.setState({busca:false,search:[]})
}
}}   onChange={(e)=>{

   this.setState({Ptxt:e.target.value})
}}></input>

<button class="btn btn-xs btn-primary"  onClick={()=>{


this._Busca()



}}>Pesquisar</button>


</div>
   <div className='alh-bt'>
   <Link class="btn btn-xs btn-primary" to={`/criar-laudo/${window.location.pathname.split("/")[2]}`}>
  <FontAwesomeIcon color='#fff' icon={faPlus} /> Novo documento
  </Link>

 
   </div>

</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                
                        <div>
                   
                        </div>
                       
                       
                        {this.state.busca == true ? <> 
                           {this.state.search.length > 0 ? <>
                         


                         {this.state.search.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                              <th scope="col">EXECUTANTE</th>
                                 <th scope="col">CONTRATANTE</th>
                           
                                 <th scope="col">MÁQUINA</th>
                                 <th scope="col">DATA</th>
                                 <th scope="col">Opções</th>
                            
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.map((item,index)=><>
                           <tr>
                          
                                  <td>
                                 
                                  {item.executante}
                                  
                                  </td>
                                 <td>       {item.contratante}</td>
                                 <td>
                                 {item.maquina}
                                 </td>
                                 <td>{this.formatarData(item.created_at)}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/laudo-tecnico/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/laudo-tecnico/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                       
                                           fetch(`${apiUrl}/laudo/remover/${item.token}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                             
                                              this.setState(prevState => ({
                                                data: prevState.data.filter(i => i.token !== item.token)
                                              }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                    </div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                   </> : <>
                   Não há dados cadastrados!
                   </>}


                    
                       </> : <>
                       <div class="p404">
 
 <img src={p404}/>
<p>Desculpe, Esse documento não foi encontrado.</p>




</div>
                       </> }
                        </> : <>
                        {this.state.data.length > 0 ? <>
                        <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                              <th scope="col">EXECUTANTE</th>
                                 <th scope="col">CONTRATANTE</th>
                           
                                 <th scope="col">MÁQUINA</th>
                                 <th scope="col">DATA</th>
                                 <th scope="col">Opções</th>
                            
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.map((item,index)=><>
                           <tr>
                          
                                  <td>
                                 
                                  {item.executante}
                                  
                                  </td>
                                 <td>       {item.contratante}</td>
                                 <td>
                                 {item.maquina}
                                 </td>
                                 <td>{this.formatarData(item.created_at)}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-success" to={`/laudo-tecnico/preview/${item.token}`}><FontAwesomeIcon color='#fff' icon={faEye} /></Link>
                                    <Link class="btn btn-info" to={`/laudo-tecnico/edit/${item.token}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger" onClick={()=>{
                                         Modal.create({
                                          titulo:"Tem certeza?",
                                          html:<><p>Após a remoção, não será possível recuperar o dado.</p>
                                          <div class="card-footer"><button type="submit" style={{background:"#dc3545"}} class="btn btn-danger float-right ml-2" onClick={()=>{
                                       
                                           fetch(`${apiUrl}/laudo/remover/${item.token}`, {
                                              method: 'DELETE',
                                              credentials: 'include',
                                              headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer '+Cookies.get("auth_token"),
                                              }
                                            }).then(response => {
                                            if(response.status == 200){
                                             
                                              this.setState(prevState => ({
                                                data: prevState.data.filter(i => i.token !== item.token)
                                              }));
                                            Modal.close()
          
                                            }
                                            })
          
          
          
                                          }}><i class="fas fa-download"></i> Remover </button></div>
                                          </>
                                      })
                                    }}> <FontAwesomeIcon icon={faTrash} /></button></div>
                                    </div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}
                        </>}
                       
                       
                       
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
      </div>

      </div>
      
     </div>
     
     </div>
      </main>
     

     <div id="modal-root"></div>
      </>
  
    );
  }
}

export default LaudoList;