import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config';
import Modal from '../../Comps/Modal';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import p404 from "../../images/undraw_text_files_au1q.png"
import { faTrash,faEye,faUniversity,faPen,faUpload, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
class Normas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loader:true,
      data:[],
      Ptxt:'',
      Tpsq:'Nome',
      search:[],
      busca:false,
      inputFocused: false,
    };
  }

  componentDidMount() {
    document.title = "Normas";
    fetch(`${apiUrl}/normas/list`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{
    this.setState({data,Loader:false})
})
}
      })
}

 
_Busca = () =>{
   fetch(`${apiUrl}/busca-usr-laudos/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      },
      body:JSON.stringify({
         Tpsq:this.state.Tpsq,
         Ptxt:this.state.Ptxt
      })
    }).then(response => {
     if(response.status == 200){
      response.json().then(search=>{
         this.setState({busca:true,search})
      })
   
     }
    })
   
}


  render() {
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    return (
     
      <>
      <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h2 class="font-semibold text-xl text-gray-800 leading-tight"> Normas</h2></div></header>
      <main>
         
      <div class="py-1">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div class="col-12">
      
      <div class="row">
      <div class="py-12">
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
         <div class="col-12">
            <div class="card">
<div class="card-header d-flex justify-content-between">
 <h4>Normas</h4>


<Link to="/normas/adicionar" class="btn btn-primary"><FontAwesomeIcon color='#fff' icon={faPlus} /> Adicionar norma</Link>
                       


</div>

               <div class="card-body">
                  <div class="row">
                     <div class="col-12">
                
                        <div>
                   
                        </div>
                        {this.state.busca == true ? <>
                     
                           {this.state.search.length > 0 ? <>
                         


                              {this.state.search.length > 0 ? <>
                           <table class="table table-sm table-bordered">
                           <thead>
                              <tr>
                                 <th scope="col">Código</th>
                                 <th scope="col">Razão social</th>
                                 <th scope="col">cnpj</th>
                                 <th scope="col">documento</th>
                                 <th scope="col">Opção</th>
                              </tr>
                           </thead>
                           <tbody>

                           {this.state.search.map((item,index)=><>
                           <tr>
                                 <td>{item.token}</td>
                                  <td>
                                  <Link  to={`/${window.location.pathname.split("/")[1]}/${item.token}/list`}>
                                  {item.token}
                                  </Link>
                                  </td>
                                  <td>{item.razao_social}</td>
                                 <td>{item.cnpj}</td>
                                 <td>
                                    <div class="btn-group btn-group-sm">
                                    <Link  to={`/criar-documento/${item.token}`}>Criar documento</Link>
                                   
                                    </div>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        Não há dados cadastrados!
                        </>}


                         
                            </> : <>
                            <div class="se-re">
      
      <img src={p404}/>
     <p>Desculpe, Esse documento não foi encontrado.</p>
    
    
   
    
    </div>
                            </> }
                      
                        </> : <>
                        {this.state.data.length > 0 ? <>
                           <table class="table ntbl table-bordered">
                           <thead>
                              <tr>
                      
                                 <th scope="col">Titulo</th>
                                 <th scope="col">Descrição</th>
                               
                                 <th scope="col">Opção</th>
                                 <th scope="col">Padão</th>

                              </tr>
                           </thead>
                           <tbody>

                           {this.state.data.map((item,index)=><>
                           <tr>
                          
                               
                                  <td>{item.titulo}</td>
                                 <td>{item.cont}</td>
                                 <td>
                                 <div class="btn-group btn-group-sm">
                                    <Link class="btn btn-primary" to={`/normas/edit/${item.id}`}><FontAwesomeIcon color='#fff' icon={faPen} /></Link>
                                    <button class="btn btn-danger"><FontAwesomeIcon color='#fff' icon={faTrash} /></button>
                       
                                   </div>
                                 </td>
                                 <td>
                                    <input type="checkbox" checked={item.padrao == 1 ? true : false} onChange={(e)=>{
                                      this.setState(prevState => {
                                       const updatedNormas = prevState.data.map(norma => {
                                         if (norma.id === item.id) {
                                           return { ...norma, padrao: e.target.checked == true ? 1 : 0 };
                                         }
                                         return norma;
                                       });
                                       return { data: updatedNormas };
                                     });
                                    }}/>
                                 </td>
                              </tr>
                           </>)}
                              

                           </tbody>
                        </table>
                        </> : <>
                        <div class="se-re">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
     
      
     
      
      </div>
                        </>}
                       
                        </>} 
                      
                     </div>
                  </div>
               </div>
               <div class="card-footer">
               <button class="btn btn-primary float-right" onClick={(e)=>{
              const filteredData = this.state.data.map(({ id, padrao }) => ({ id, padrao }));
              
e.target.classList.add('load')
              fetch(`${apiUrl}/normas/atualizar-padrao`, {
               method: 'POST',
               credentials: 'include',
               headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Bearer '+Cookies.get("auth_token"),
               },
               body:JSON.stringify({data:filteredData})
             }).then(response => {
               e.target.classList.remove('load')
               toast.success("Padão de norma atualizado com sucesso!")
             })




               }}><FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
                       
               </div>
            </div>
         </div>
      </div>
   </div>
      </div>
      </div>
      
     </div>
     
     </div>
      </main>
     

     <div id="modal-root"></div>
      </>
  
    );
  }
}

export default Normas;