import React, { Component } from 'react';
import logo from "../../../src/images/logo-c.png"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, NavLink } from 'react-router-dom';
import { apiUrl } from '../../config';
import { Drop, Dropdown, Item } from '../Dropdown';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
    enableMenu:false
    };
  }



  getClassNames = ({ isActive }) => {
  return isActive ? "inline-flex items-center px-1 pt-1 border-b-2 border-indigo-400 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-indigo-700 " :
   "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300"
  };




  render() {

    return (
     
      <>
<nav class="bg-white border-b border-gray-100">
   <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
         <div class="shrink-0 flex items-center">
            <div class="logo-t">
               <Link to="/">
               <img src={logo} width={100} height={100}/>
               </Link>
            </div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink exact className={this.getClassNames} to="/"> Apreciação de Riscos </NavLink>
            </div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink exact className={this.getClassNames} to="/laudo-tecnico">Laudo técnico</NavLink>
            </div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/cadastro-executante"> Cadastro do executante </NavLink>
            </div>
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/cadastro-solicitante"> Cadastro do solicitante </NavLink>
            </div>
         
            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/normas"> Normas </NavLink>
            </div>

            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/tecnicos"> Técinicos </NavLink>
            </div>

            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/galeria"> Galeria </NavLink>
            </div>


            <div class="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex">
               <NavLink  className={this.getClassNames} to="/textos"> Textos </NavLink>
            </div>
            
         </div>
         <div class="hidden sm:flex sm:items-center sm:ml-6">
            <div class="ml-3 relative">
            </div>
            <div class="ml-3 relative">
               <div class="relative">
                  
               </div>
            </div>
         </div>
         <div class="-mr-2 flex items-center sm:hidden">
            <button class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition" onClick={()=>
               {
               this.setState({enableMenu:!this.state.enableMenu})
               }}>
               <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  {this.state.enableMenu == false ? <>
                  <path class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                  </> : <>
                  <path class="inline-flex" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                  </>}
               </svg>
            </button>
         </div>
      </div>
      {this.state.enableMenu && <>
      <div class="block sm:hidden">
         <div class="pt-4 pb-1 border-t border-gray-200">
            <div class="flex items-center px-4">
            </div>
            <div class="mt-3 space-y-1">
               <div><button class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition w-full text-left"> Sair </button></div>
            </div>
         </div>
      </div>
      </>}
   </div>
   <div class="hidden sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
         <div>
            <Link class="block pl-3 pr-4 py-2 border-l-4 border-indigo-400 text-base font-medium text-indigo-700 bg-indigo-50 focus:outline-none focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition" to="/">
            Dashboard </Link>
         </div>
      </div>
      <div class="pt-4 pb-1 border-t border-gray-200">
         <div class="flex items-center px-4">
            <div>
               <div class="font-medium text-base text-gray-800">Administrador</div>
               <div class="font-medium text-sm text-gray-500">admin@admin.com.br</div>
            </div>
         </div>
         <div class="mt-3 space-y-1">
            <form method="POST">
               <div><button class="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-none focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition w-full text-left"> Sair </button></div>
            </form>
         </div>
      </div>
   </div>
</nav>


      </>
  
    );
  }
}

export default Header;