import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiUrl } from '../../config';
import { json } from 'react-router-dom';
class Situation extends Component {
    constructor(props) {
        super(props);
        this.state = {
        data:[],
        slc:undefined
        }
    }
    componentDidMount(){

        const matchedItem = this.props.data.find(item => item.code === this.props.code);
       if(matchedItem != undefined){
        this.setState({slc:matchedItem.item})
       }
    }

    Resp(e){
        if(e == "a"){
        return(<spam>Não Aplicável(eis) </spam>)
        }else if(e == "b"){
            return(<span style={{color:'green'}}>CONFORME</span>)
        }else if(e == "c"){
        return(<span style={{color:'red'}}>NÃO CONFORME</span>)
        }else{
        return('Não Aplicável(eis) ')
        }
      
    }


HandleSubmit(e){



    this.setState({slc:e.target.value})
    const id = toast.loading("Salvando")
   
    
    fetch(`${apiUrl}/situation/${this.props.code}`, {
        method: 'POST',
        credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
        body: JSON.stringify({
            item:e.target.value,
            ref:window.location.pathname.split("/")[3],
        })
       }).then(response => {
    if(response.status == 200){
        toast.update(id, { render:"Salvo com sucesso!", type: "success", isLoading: false,autoClose: 3000});
    }
       })
}




  render() {
  
    if(window.location.pathname.split("/")[1] == "previewLau"){
      
      
      
      
        return(<div><strong>Situação:</strong> {this.Resp(this.state.slc)}</div>)
    }

    

    return (<div style={{marginTop:10}}>
    <strong>Situação: </strong>
    <select onChange={(e)=>{
        this.HandleSubmit(e)
    }} value={this.state.slc}>
        <option value="a">Não Aplicável (eis)</option>
        <option value="b">CONFORME</option>
        <option value="c">NÃO CONFORME</option>
    </select>
    
    </div>);
  }
}

export default Situation;