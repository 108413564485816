import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

// Registrando os componentes necessários para o Chart.js
ChartJS.register(Title, Tooltip, Legend, ArcElement);

// Componente de gráfico de pizza
const GraficoPizza = ({ data }) => {
  // Definindo os dados completos
  const tabelaData = [
    { descricao: "Aceitável", valor: 1, percentual: "14,28", cor: "green" },
    { descricao: "Baixo", valor: 1, percentual: "14,28", cor: "lightgreen" },
    { descricao: "Moderado", valor: 1, percentual: "14,28", cor: "yellow" },
    { descricao: "Significativo", valor: 1, percentual: "14,28", cor: "gold" },
    { descricao: "Alto", valor: 2, percentual: "28,60", cor: "orange" },
    { descricao: "Muito Alto", valor: 0, percentual: "0", cor: "red" },
    { descricao: "Extremo", valor: 1, percentual: "14,28", cor: "darkred" }
  ];

  // Mapeando os dados para ajustar os valores conforme a seleção
  const adjustedData = tabelaData.map(item => {
    const isIncluded = data.includes(item.descricao.toUpperCase());

    return {
      ...item,
      valor: isIncluded ? item.valor : 0
    };
  });

  // Preparando os dados para o gráfico de pizza
  const chartData = {
    labels: adjustedData.map(item => item.descricao),
    datasets: [
      {
        data: adjustedData.map(item => item.valor),
        backgroundColor: adjustedData.map(item => item.cor),
        borderColor: '#fff',
        borderWidth: 1
      }
    ]
  };

  return (
    <div>
      <h2>Gráfico de Risco</h2>
      <Pie data={chartData} />
    </div>
  );
};

export default GraficoPizza;