import React, { Component } from 'react';
import "./Tree.css"
let nodeIdCounter = 0;
class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      save:[],
      id: nodeIdCounter++
    };
  }

componentDidMount(){

}

  handleSelect = (e) => {
    const { node, level,save } = this.props;
    const { isActive,id } = this.state;
    console.log(save)

/*
    if(e.target.parentNode.childNodes[1] != undefined){
        var arr = (e.target.parentNode.parentNode.childNodes)
    var check1 = false;
        for (let i = 0; i < arr.length; i++) {
       if(arr[i].childNodes[0].classList.contains("selected")){
        check1 = true;
        break;
       }
        }
     console.log(check1)
    }

*/


if(e.target.parentNode.parentNode.parentNode.children[0].className == "diagram"){
this.setState({ isActive: true });
}




if(e.target.parentNode.parentNode.parentNode.children[0].classList.contains('selected')){
    if(isActive){
     if(e.target.parentNode.childNodes[1] != undefined){
        var array = e.target.parentNode.childNodes[1].childNodes;
        var check = false;
        for (let i = 0; i < array.length; i++) {
       
       if(array[i].childNodes[0].classList.contains("selected")){
       check = true;
       break;
       }

        }
     if(check == false){
        this.props.onSelect(node.name,'r',id);
        this.setState({ isActive: false }); 
     }
     }else{
        this.props.onSelect(node.name,'r',id);
        this.setState({ isActive: false }); 
     }
 
    }else{
        this.props.onSelect(node.name,'a',id);
        this.setState({ isActive: true }); 
    }

}

    if (this.props.onSelect) {
      

      // Verifica se a seleção é permitida
      if (!isActive && (this.props.allowSelect || this.props.isParentActive)) {
        
       //this.setState({ isActive: true });
      }else{
       // this.setState({ isActive: false }); 
      }
    }
  };

  render() {
    const { node, save,level,key } = this.props;
    const {id} = this.state;
if(id == 0){
    var isActive = true;
}else if(save.includes(`${node.name}-${id}`)){
var isActive = true;
}else{
    var isActive = false;
}

    return (
    
     <div className="tree-node" style={{ marginLeft: `${0}px` }}>
        <button code={`${node.name}-${id}`}
          className={`node-button ${isActive ? 'selected' : ''}`}
          onClick={this.handleSelect}
        >
          {node.name}

        </button>
        {node.children && (
          <div className="node-children">
            {node.children.map((child, index) => (<>
            
                <TreeNode
                key={index}
                node={child}
                onSelect={this.props.onSelect}
                save={this.props.save}
                level={index+1}
              />
              
            </>
            
            ))}
          </div>
        )}
    </div>
 
    );
  }
}

export default TreeNode;